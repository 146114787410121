!function(a){var b=/iPhone/i,c=/iPod/i,d=/iPad/i,e=/(?=.*\bAndroid\b)(?=.*\bMobile\b)/i,f=/Android/i,g=/(?=.*\bAndroid\b)(?=.*\bSD4930UR\b)/i,h=/(?=.*\bAndroid\b)(?=.*\b(?:KFOT|KFTT|KFJWI|KFJWA|KFSOWI|KFTHWI|KFTHWA|KFAPWI|KFAPWA|KFARWI|KFASWI|KFSAWI|KFSAWA)\b)/i,i=/Windows Phone/i,j=/(?=.*\bWindows\b)(?=.*\bARM\b)/i,k=/BlackBerry/i,l=/BB10/i,m=/Opera Mini/i,n=/(CriOS|Chrome)(?=.*\bMobile\b)/i,o=/(?=.*\bFirefox\b)(?=.*\bMobile\b)/i,p=new RegExp("(?:Nexus 7|BNTV250|Kindle Fire|Silk|GT-P1000)","i"),q=function(a,b){return a.test(b)},r=function(a){var r=a||navigator.userAgent,s=r.split("[FBAN");if("undefined"!=typeof s[1]&&(r=s[0]),s=r.split("Twitter"),"undefined"!=typeof s[1]&&(r=s[0]),this.apple={phone:q(b,r),ipod:q(c,r),tablet:!q(b,r)&&q(d,r),device:q(b,r)||q(c,r)||q(d,r)},this.amazon={phone:q(g,r),tablet:!q(g,r)&&q(h,r),device:q(g,r)||q(h,r)},this.android={phone:q(g,r)||q(e,r),tablet:!q(g,r)&&!q(e,r)&&(q(h,r)||q(f,r)),device:q(g,r)||q(h,r)||q(e,r)||q(f,r)},this.windows={phone:q(i,r),tablet:q(j,r),device:q(i,r)||q(j,r)},this.other={blackberry:q(k,r),blackberry10:q(l,r),opera:q(m,r),firefox:q(o,r),chrome:q(n,r),device:q(k,r)||q(l,r)||q(m,r)||q(o,r)||q(n,r)},this.seven_inch=q(p,r),this.any=this.apple.device||this.android.device||this.windows.device||this.other.device||this.seven_inch,this.phone=this.apple.phone||this.android.phone||this.windows.phone,this.tablet=this.apple.tablet||this.android.tablet||this.windows.tablet,"undefined"==typeof window)return this},s=function(){var a=new r;return a.Class=r,a};"undefined"!=typeof module&&module.exports&&"undefined"==typeof window?module.exports=r:"undefined"!=typeof module&&module.exports&&"undefined"!=typeof window?module.exports=s():"function"==typeof define&&define.amd?define("isMobile",[],a.isMobile=s()):a.isMobile=s()}(this);

$( document ).ready(function() {
	if (isMobile.apple.phone || isMobile.apple.ipod || isMobile.android.phone || isMobile.seven_inch) {

		var body = document.querySelector('body');
		var widget = document.querySelector('.fo_widget');
		var buttons = document.querySelectorAll('.fo_item');
		var submenus = document.querySelectorAll('.fo_submenu');
		var close = document.querySelector('.fo_close');
		var backdrop = document.querySelector('.fo_backdrop');
		var mini = document.querySelector('.fo_mini');
		var bodyWidth = $(body).width();
		var miniWidth = $(mini).width();
		var miniHeight = $(mini).height();
		var widgetFontSize = +$(widget).css('font-size').replace(/\D+/g,"");
		var viewport = $('meta[name="viewport"]');
		var state = 0; //состояние виджета, 0 - скрыто, 1 дефолт, 2 открыто подменю
		$(widget).css("border", "solid 1px transparent");
		function preventPan(e) {
			e.preventDefault();
		}

		function hideWidget() {
			widget.classList.add('-hidden');
			mini.classList.remove('-hidden');
			state = 0;
		}
		function showWidget() {
			widget.classList.remove('-hidden');
			mini.classList.add('-hidden');
			state = 1;
			$(widget).css("border", "solid 0px transparent");
		}
		function closeSubmenu() {
			widget.classList.remove('-active');
			backdrop.classList.remove('-active');
			body.classList.remove('-active');
			for (var i = 0; i < buttons.length; i++) {
				buttons[i].classList.remove('-active');
				$(submenus[i]).slideUp(200);
			}
			document.removeEventListener('touchmove', preventPan);
			state = 1;
		}

		function setScale() {
			var ratio = window.innerWidth / 1100;
			if (window.innerWidth) {
				if ( window.innerWidth > 320 ) {
					mini.classList.remove('-none');
					widget.classList.remove('-none');
					mini.style.width = miniWidth * ratio + 'px';
					mini.style.height = miniHeight * ratio + 'px';
					widget.style.fontSize = widgetFontSize * ratio + 'px';
				} else {
					mini.classList.add('-none');
					widget.classList.add('-none');
				}
			}
		}
		function preventScroll() {
			setTimeout( function(){
				if (window.innerHeight > widget.offsetHeight && state === 2) {
					$(submenus).bind('touchmove', preventPan);
				} else {
					$(submenus).unbind('touchmove', preventPan);
				}
			}, 400 );
		}

		setTimeout(function(){
			setScale();
			hideWidget();
			$(mini).trigger( "shown" );
		}, 500);
		mini.addEventListener('click', showWidget);
		document.addEventListener('touchmove', function(){
			if (state === 1) {
				closeSubmenu();
				hideWidget();
			}
		});
		close.addEventListener('click', closeSubmenu);
		backdrop.addEventListener('click', closeSubmenu);

		for (var i = 0; i < buttons.length; i++) {
			(function (submenu) {
				buttons[i].addEventListener('click', function () {
					this.classList.add('-active');
					widget.classList.add('-active');
					$(submenu).slideDown(200);
					backdrop.classList.add('-active');
					body.classList.add('-active');
					state = 2;
					preventScroll();
				});
			})(submenus[i]);
		}

		window.addEventListener('touchend', setScale);
		window.addEventListener('orientationchange', function(){
			setTimeout(setScale, 100);
		});
		window.addEventListener('orientationchange', preventScroll);

	}
});