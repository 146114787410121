$(document).ready(function(){
	jeep_class.init_top_menu();
	jeep_class.fix_footer_menu_height();
	$('.to_top').click(function(){
		$('body').scrollTo('#start_content', 200);
		return false;
	});
	$('.test_drive_btn').click(openTestDriveForm);
	$('.feed_back_btn').click(openFeedBackForm);
	// $('.estimation_btn').click(openEstimationForm);
	$('.сredit_calc_btn').click(сreditCalcForm);
	$('body').on('click', '.ask_question_btn', openAskQuestionForm);
});

var jeep_class = function(){
	var $class_obj = {};
	function intiRootModelsBanner(){
		$('.models_banner .root_models_menu .root_models_menu_item').mouseenter(function(){
			var model_name = $(this).attr('data-model-name');
			showRootBannerSlider(model_name);
		}).mouseleave(function(){
			hideActiveRootBannerModel();
		});
	}
	function showRootBannerSlider(modelCode){
		hideActiveRootBannerModel();
		$('.models_banner .root_models_menu .root_models_menu_item').removeClass('active');
		$('.models_banner .root_models_menu .root_models_menu_item.'+ modelCFode).addClass('active');
		$('.models_banner .models_image_item').stop();
		$('.models_banner .models_image_item.'+modelCode).addClass('active').animate(
			{ opacity: 1},
			200, 'linear'
		);

	}
	function hideActiveRootBannerModel(){
		$('.models_banner .models_image_item').removeClass('active').css('opacity', 0);
		$('.models_banner .root_models_menu .root_models_menu_item').removeClass('active');
	}
	function initTopMenu(){
		$('.top_menu_link').mouseover(function(){
			$('.content_menu_opacity').stop().show().animate({opacity: 0.3}, 250);
			$(this).closest('.top_menu_item').addClass('hover');
		});
		$('.top_menu_list .top_menu_item').mouseleave(function(){
			$('.top_menu_list .top_menu_item').removeClass('hover');
		});
		$('.top_menu_list').mouseleave(function(){
			$('.content_menu_opacity').stop().animate({opacity: 0}, 250, function(){
				$(this).hide();
			});
		});
		$('.top_menu_link_model').mouseover(function(){
			$(this).closest('.top_menu_item_model').addClass('hover');
		});
		$('.top_menu_item_model').mouseleave(function(){
			$('.top_menu_list .top_menu_item_model').removeClass('hover');
		});
	}
	function fixFooterMenuHeight(){
		$('.footer_menu').fixHeight('.footer_col', 4);
	}
	function initRootActionJumper(){
		$('.actions_root_list .standart_item').hover(
			function(){
				$(this).addClass('hover').find('.special_img_link').stop().animate({ top: -60}, 150);
			},
			function(){
				$(this).removeClass('hover').find('.special_img_link').stop().animate({ top: 0}, 150);
			}
		);

		$('.actions_root_list .standart_item .special_img_link').hover(
			function(){
				$(this).closest('.standart_item').find('.standart_item_header').addClass('hover');
			},
			function(){
				$(this).closest('.standart_item').find('.standart_item_header').removeClass('hover');
			}
		);
		$('.root_action_wrapper_renew .root_action_item').hover(
			function(){
				$(this).stop().animate({ top: -130}, 150);
			},
			function(){
				$(this).stop().animate({ top: 0}, 150);
			}
		);

	}
	function fixActionsListHeight(){
		$('.actions_list').fixHeight('.standart_item', 3);
	}
	function fixCarsListItemHeight(){
		$('.cars_list').fixHeight('.standart_item', 2);
	}
	function initRootBanner(){
		$('.root_list_banners').bxSlider({
			speed: 900,
			auto: true,
			pause: 7000,
			slideWidth: 1600,
			minSlides: 1,
			maxSlides: 1,
			moveSlides: 1,
			pager: false,
			adaptiveHeight: true,
			hideControlOnEnd: true,
			mode: 'fade',
			prevText: '',
			nextText: '',
			nextSelector: '.slide_next',
			prevSelector: '.slide_prev',
			slideMargin: 0
		});
	}
	$class_obj = {
		fix_cars_list_item_height: fixCarsListItemHeight,
		fix_actions_list_height: fixActionsListHeight,
		init_top_menu: initTopMenu,
		fix_footer_menu_height: fixFooterMenuHeight,
		init_root_actions_jumper: initRootActionJumper,
		init_root_banner: initRootBanner,
		init_root_models_banner: intiRootModelsBanner

	};
	return $class_obj;
}();

function hideListElements(selector,n) {
	if ($(selector).find('li').size() <= n) {
		$(selector).find('.plus').hide();
		return false;
	}
	$(selector).find('.plus').show();
	$(selector).find('li').hide();
	$(selector).find('li:lt('+n+')').show();
	$(selector).find('.minus').removeClass('minus').addClass('plus');
	$('.plus span').text('Показать все');
	return true;
}
function showListElements(selector) {
	$(selector).find('li').show();
	$(selector).find('.plus').removeClass('plus').addClass('minus');
	$('.minus span').text('Скрыть все');
}
function toggleListElements(selector,n) {
	if ($(selector).find('.plus').size() > 0) {
		showListElements(selector);
	}
	else {
		hideListElements(selector,n);
	}
}
function initUsedCardSlider() {
	var slider;
	slider = $('.bxslider-container').bxSlider({
		mode: 'horizontal',
		moveSlides: 1,
		maxSlides: 4,
		minSlides: 4,
		slideWidth: 141,
		slideMargin: 21,
		pager: false,
		nextText: '',
		prevText: '',
		onSlideNext: function(el) {
			makeActive(el.data('slide-index'));
		},
		onSlidePrev: function(el) {
			makeActive(el.data('slide-index'));
		},
		onSliderLoad: function() {
			$('#bxslider').css({ 'visibility' : 'visible' });
		}
	});
	$('.bxslider-container').on('click', 'li', function(){
		var index = $(this).data('slide-index');
		makeActive(index);
		slider.goToSlide(index);
	});
	$('.touchslider-item').click(function() {
		makeActive($(this).index());
	});
	$('#slide_left').click(function(e) {
		e.preventDefault();
		slider.goToPrevSlide();
	});
	$('#slide_right').click(function(e) {
		e.preventDefault();
		slider.goToNextSlide();
	});
	$('.touchslider-item img, #target_image').load(function() {
		$('.used_image_wrapper').css({ visibility: 'visible' });
	});
	makeActive(0);

	function makeActive(index) {
		var object = $('.bxslider-container li[data-slide-index = '+index+']');
		if (object.length == 0 ) return false;
		var image = $('#target_image');
		if (image.length != 0) {
			image.attr('src', object.find('img').data('src'));
		}
		else {
			image = $('<img src="#" id=target_image>');
			image.click(slider.goToNextSlide);
			$('#bxslider').prepend(image);

		}
		image.attr('src',  object.find('img').data('src'));
		$('.bxslider-container li').removeClass('current');
		object.addClass('current');
		return true;
	}
}


function initIkSelect(ikSelectInFwWin) {
	var custom_class = 'select_form';
	if (ikSelectInFwWin) custom_class = custom_class + ' fwin_select';
	$('select').ikSelect({
		syntax: '<div class="ik_select_link sprite_before"><span class="ik_select_link_text"></span></div><div class="ik_select_dropdown select_form_dropdown"><div class="ik_select_list select_form_list"></div></div>',
		customClass: custom_class,
		linkCustomClass: 'select_form_link',
		autoWidth: false,
		ddFullWidth: false,
		ddMaxHeight: 400
	});
}

function initTimePicker(default_time){
	return $('input[name=time]').timepicker({
		defaultTime: default_time,
		useBackground: false,
		countColumn: 1
	});
}
function initDatePicker(default_date, timePicker){
	var inst = $('input[name=date]');
	inst.datepicker({
		inline: true,
		minDate: new Date(),
		onSelect: function (dateText, inst) {
			$(this).trigger('change');

			if(!timePicker) return true;
			var selected_date = Date.parse(dateText.split('.').reverse().join('.')),
				date_obj = new Date(),
				current_date = Date.parse((date_obj.getMonth()+1)+'/'+date_obj.getDate()+'/'+date_obj.getFullYear());
			if (selected_date === current_date) {
				var minutes = date_obj.getMinutes(),
					time = (date_obj.getHours() + 1) + ':' + (minutes < 10 ? '0' : '') + minutes;
				timePicker.disabledUntilTime(time);
			}
			else{
				timePicker.clearDisabled();
			}
			return true;
		}
	});

	if (default_date != 'undefined') {
		inst.datepicker('setDate', default_date);
		inst.datepicker( "option", "dateFormat", "dd.mm.yy" );
	}
}
function initEstimationForm() {
	initForm();
	var select = $('select[name=brand]');
	fw_MOJAX.send({
		url : "/ajax/cars/getBrandsList/",
		user_success: function(json_data) {
			var optionsHtml = '',
				i;
			for(i = 0; i < json_data.brands.length; i++)
				optionsHtml += '<option value="' + json_data.brands[i].name + '">' + json_data.brands[i].name + '</option>';
			$(select).html("<option value=''>Не выбрано</option>" + optionsHtml);
			$(select).ikSelect('reset').ikSelect('redraw');
		},
		overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
		overlay_to: $(select).closest('.form_field_layout')
	});

	$('select[name=brand]').change(function() {
		var brand = $(this).val();
		var select = $("select[name=model]");
		if (!$.empty(brand)) {
			fw_MOJAX.send({
				url : "/ajax/cars/getModelsList/",
				data: {brand: brand},
				user_success: function(json_data) {
					console.log(json_data.models);
					var optionsHtml = '',
						i;
					for(i = 0; i < json_data.models.length; i++)
						optionsHtml += '<option value="' + json_data.models[i].name + '">' + json_data.models[i].name + '</option>';
					$(select).html("<option value=''>Не выбрано</option>" + optionsHtml);
					$(select).ikSelect('reset').ikSelect('redraw');
				},
				overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
				overlay_to: $(select).closest('.form_field_layout')
			});
		}
	});
}
function initForm(hwnd){
	var date = new Date();
	date.setDate(date.getDate() + 1);
	initDatePicker(date, initTimePicker("13:00"));
	initIkSelect();
	hwnd && $(hwnd.content).on('click', '.success_close_button', function(){
		hwnd.close();
	});
}

function openTestDriveForm(event){
	var hwnd = fw_WIN.create({
		title: "Запись на тест-драйв"
	});
	var model_id = $(event.target).data('model_id');
	fw_MOJAX.send({
		to: hwnd.content,
		url: "/ajax/forms/getTestDriveForm/",
		data: {model_id: model_id},
		user_success: function(){
			$(hwnd.content).find('.type_select_test_drive').find('option[data-model_id="' + model_id + '"]').attr('selected', true);
			initForm(hwnd);
			$('[name=phone]').ready(function () {
				$('[name=phone]').inputmask('+7 (\\999) 999-99-99', { showMaskOnHover: false });
			});
		},
		overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
		overlay_to: hwnd.content
	});
}

function openAskQuestionForm(){
	var hwnd = fw_WIN.create({
		title: "Задать вопрос"
	});
	fw_MOJAX.send({
		to: hwnd.content,
		url: "/ajax/faq/showAskForm/",
		user_success: function(){
			$(".fw_win_wrapper input, .fw_win_wrapper textarea").removeAttr('placeholder');
			initForm(hwnd);
		},
		overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
		overlay_to: hwnd.content
	});
}
function сreditCalcForm() {
	var hwnd = fw_WIN.create({
		title: "РАСЧЕТ КРЕДИТА"
	});
	fw_MOJAX.send({
		to: hwnd.content,
		url: "/ajax/forms/getCreditCalcForm/",
		user_success: function(){
			initForm(hwnd);
			var car_for_form = $('#car_for_form');
			var hidden_fields = ['model_name','image','volume','hp','engine','gear','year','color_rgb','color_full','no_price','price_have_discount','price','total_price','order_num'];
			$(hwnd.content).find('.form_custom_info_wrapper').append(car_for_form.html());
			$(hwnd.content).find('.form_fields_group_wrapper').find('input[name="link"]').val(location.href);
			for(var i = hidden_fields.length - 1; i >= 0; i--){
				$(hwnd.content).find('.form_fields_group_wrapper').find('input[name="' + hidden_fields[i] + '"]').val(car_for_form.data(hidden_fields[i]));
			}
		},
		overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
		overlay_to: hwnd.content
	});
}
function openEstimationForm() {
	var hwnd = fw_WIN.create({
		title: "Оценка автомобиля"
	});
	fw_MOJAX.send({
		to: hwnd.content,
		url: "/ajax/forms/getEstimationForm/",
		user_success: function(){
			initForm(hwnd);
			var car_for_form = $('#car_for_form');
			var hidden_fields = ['model_name','image','volume','hp','engine','gear','year','color_rgb','color_full','no_price','price_have_discount','price','total_price','order_num'];
			$(hwnd.content).find('.form_custom_info_wrapper').append(car_for_form.html());
			$(hwnd.content).find('.form_fields_group_wrapper').find('input[name="link"]').val(location.href);
			for(var i = hidden_fields.length - 1; i >= 0; i--){
				$(hwnd.content).find('.form_fields_group_wrapper').find('input[name="' + hidden_fields[i] + '"]').val(car_for_form.data(hidden_fields[i]));
			}
		},
		overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
		overlay_to: hwnd.content
	});
}
function openFeedBackForm(){
	var hwnd = fw_WIN.create({
		title: "Обратная связь"
	});
	fw_MOJAX.send({
		to: hwnd.content,
		url: "/ajax/forms/getFeedBackForm/",
		user_success: function(){
			initForm(hwnd);
			var car_for_form = $('#car_for_form');
			var hidden_fields = ['model_name','image','volume','hp','engine','gear','year','color_rgb','color_full','no_price','price_have_discount','price','total_price','order_num'];
			$(hwnd.content).find('.form_custom_info_wrapper').append(car_for_form.html());
			$(hwnd.content).find('.form_fields_group_wrapper').find('input[name="link"]').val(location.href);
			for(var i = hidden_fields.length - 1; i >= 0; i--){
				$(hwnd.content).find('.form_fields_group_wrapper').find('input[name="' + hidden_fields[i] + '"]').val(car_for_form.data(hidden_fields[i]));
			}
		},
		overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
		overlay_to: hwnd.content
	});
}
function openReserveCarForm(){
	var hwnd = fw_WIN.create({
		title: "Обратный звонок"
	});
	fw_MOJAX.send({
		to: hwnd.content,
		url: "/ajax/forms/getReservationForm/",
		user_success: function(){
			initForm(hwnd);
			var car_for_form = $('#car_for_form');
			var hidden_fields = ['model_name','image','volume','hp','engine','gear','year','color_rgb','color_full','no_price','price_have_discount','price','total_price','order_num'];
			$(hwnd.content).find('.form_custom_info_wrapper').append(car_for_form.html());
			$(hwnd.content).find('.form_fields_group_wrapper').find('input[name="link"]').val(location.href);
			for(var i = hidden_fields.length - 1; i >= 0; i--){
				$(hwnd.content).find('.form_fields_group_wrapper').find('input[name="' + hidden_fields[i] + '"]').val(car_for_form.data(hidden_fields[i]));
			}
			$('[name=phone]').ready(function () {
				$('[name=phone]').inputmask('+7 (\\999) 999-99-99', { showMaskOnHover: false });
			});
		},
		overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
		overlay_to: hwnd.content
	});
}
function openLearMoreForm(){
	var hwnd = fw_WIN.create({
		title: "Узнать больше об этом автомобиле"
	});
	fw_MOJAX.send({
		to: hwnd.content,
		url: "/ajax/forms/getLearMoreUsedForm/",
		user_success: function(){
			initForm(hwnd);
			var car_for_form = $('#car_for_form');
			var hidden_fields = ['brand','model_name','image','volume','hp','engine','gear','year','run','price_have_discount','price','total_price','textintegration'];
			$(hwnd.content).find('.form_custom_info_wrapper').append(car_for_form.html());
			$(hwnd.content).find('.form_fields_group_wrapper').find('input[name="link"]').val(location.href);
			for(var i = hidden_fields.length - 1; i >= 0; i--){
				$(hwnd.content).find('.form_fields_group_wrapper').find('input[name="' + hidden_fields[i] + '"]').val(car_for_form.data(hidden_fields[i]));
			}
			$('[name=phone]').ready(function () {
				$('[name=phone]').inputmask('+7 (\\999) 999-99-99', { showMaskOnHover: false });
			});
		},
		overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
		overlay_to: hwnd.content
	});
}
function initFaqSearchForm() {
	var faq_form = $('form.faq_search');

	function faqSearch(page) {
		faq_form.find('input[name=page]').val(page);
		faq_form.submit();
	}

	faq_form.find('.faq_search_button').click(function(){
		faqSearch(1);
	});
	$('#search_results').on('click', '.faq_search_paginator', function(e) {
		if ($(this).hasClass('enabled')) {
			faqSearch($(this).data().page);
		}
	});
}

function shortPopUp(title, content) {
	var win = fw_WIN.create({
		offset: 0,
		title: title ? '<h1>' + title + '</h1>' : '',
		container_class: "form_window",
		content_class: "fw_win_form_content",
		overlay_class: "form_overlay",
		content: '',
		lock_page: false
	},{
		onAfterOpen: function(inst){
			inst.close_button.append('<span class="hidden-xs"></span><div class="block_icon icon_plus black rotate_45"></div>');
		}
	});
	$(win.content).html(content);
}





function setCarBenefitsSlider() {
	var slider = $(".benefits_slider");
	var list = slider.find(".benefits_list");
	var collection = list.find(".benefit_card");
	var next_button = slider.find(".next");
	var prev_button = slider.find(".prev");
	var current_index = 0;
	var current_offset = 0;
	var count = collection.length;

	function updateCurrentOffset(){
		current_offset = $(collection.get(current_index)).position().left;
	}
	function updateInterface(){
		next_button.removeClass("disabled");
		prev_button.removeClass("disabled");
		if(isLast()){
			next_button.addClass("disabled");
		}
		if(isFirst()){
			prev_button.addClass("disabled");
		}
	}
	function isFirst(){
		return current_index == 0;
	}
	function isLast(){
		return current_index == (count - 1);
	}
	function next(){
		if(isLast()) return;
		current_index++;
		updateCurrentOffset();
		updateCurrentPosition();
		updateInterface();
	}
	function prev(){
		if(isFirst()) return;
		current_index--;
		updateCurrentOffset();
		updateCurrentPosition();
		updateInterface();
	}
	function updateCurrentPosition(){
		list.css({marginLeft: -current_offset});
	}
	function init(){
		updateCurrentOffset();
		updateCurrentPosition();
		if(count > 1){
			next_button.removeClass("hidden");
			prev_button.removeClass("hidden");
			updateInterface();
			next_button.click(function(){
				next();
			});
			prev_button.click(function(){
				prev();
			});
			// if($.swiperight) {
			// slider.swiperight(function(){
			// 	prev();
			// });
			// slider.swipeleft(function(){
			// 	next();
			// });
			// }
		}
	}

	if(slider.length > 0){
		init();
	}

}





var animation_360 = false;
var scroll_top_px = 420;
$(document).ready(function(){
	//initKwicks();
	$('.scroll_top').click(function(){
		$(window).scrollTo(scroll_top_px, 600);
	});
	$('.template_switch .switch_buttons a').click(function(){
		$(this).addClass('sel').siblings().removeClass('sel');
		$('#' + $(this).attr('rel')).fadeIn().siblings().fadeOut();
	});
	/*if( $('.template_360').size() ){
		//- Preloader ---------------------------------------------------------------
		var load360array = []
		function load360(src) {
			var deferred = $.Deferred();
			var sprite = new Image();
			sprite.onload = function() {
				deferred.resolve();
			};
			sprite.src = src;
			return deferred.promise();
		}
		//--------------------------------------------------------------------------
		var model = $('.template_360 .stage').html();
		var slides = 36;
		var stage = '';
		load360array.push(load360('/files/resources/img/loading.gif'));
		$('.template_360 .stage').html('<div class="loader"></div>');
		for(var i = 1; i <= slides; i++){

			var pngfile = '/files/resources/models/'+ model +'/360/slide_'+ ( i < 10 ? '0'+i : i ) +'.png';

			load360array.push(load360(pngfile));

			//stage += '<div class="slide '+ (i == slides ? 'sel' : '') +'"><img src="#" alt="'+ pngfile +'" /></div>';
			stage += '<div class="slide '+ (i == slides ? 'sel' : '') +'"><img src="'+ pngfile +'" alt="" /></div>';

		}

		$.when.apply(null, load360array).done(function(){
			$('.template_360 .stage').html(stage);
			$('.template_360 .stage').click(function(e){
				var sel = $('.template_360 .stage .sel');
				var l = e.pageX - $('.template_360').offset().left - $('.template_360 .zoom').width()/2;
				var t = e.pageY - $('.template_360').offset().top - $('.template_360 .zoom').width()/2;
				$('.template_360 .zoom .glass').css('background-image', 'url('+ sel.find('img').attr('src').replace('.png','.jpg') +')');
				$('.template_360 .zoom').show().css('left', l).css('top', t);
				setZoomGlass(l,t);
			});
			$('.template_360 .prev, .template_360 .next').click(function(){
				if( animation_360 ) return;
				$('.template_360 .zoom').hide();
				var sel = $('.template_360 .stage .sel');

				if( !sel.size() )
					sel = $('.template_360 .stage .slide').first().addClass('sel');

				var next;

				if( $(this).hasClass('next') )
					next = sel.prev().size() ? sel.prev() : $('.template_360 .stage .slide').last();
				else
					next = sel.next().size() ? sel.next() : $('.template_360 .stage .slide').first();

				animation_360 = true;

				if( next.find('img').attr('src') == '#' ){

					$('.template_360 .zoom').append('<div class="loader"></div>');
					$('.template_360 .zoom .glass').css('background','#FFFFFF');

					next.find('img').one('load',function(){

						$('.template_360 .loader').detach();

						sel.removeClass('sel').fadeOut(200);
						next.addClass('sel').fadeIn(200);

						sel = next;

						//$('.template_360 .zoom .glass').css('background-image', 'url('+ sel.find('img').attr('src').replace('.png','.jpg') +')');
						setZoomGlass( $('.template_360 .zoom').position().left , $('.template_360 .zoom').position().top );

						animation_360 = false;

					}).attr('src', next.find('img').attr('alt') );

				}else{

					sel.removeClass('sel').fadeOut(200);
					next.addClass('sel').fadeIn(200);

					sel = next;
					$('.template_360 .zoom .glass').css('background-image', 'url('+ sel.find('img').attr('src').replace('.png','.jpg') +')');
					setZoomGlass( $('.template_360 .zoom').position().left , $('.template_360 .zoom').position().top );

					animation_360 = false;
				}

			});

			$('.template_360 .next').click();
			setZoomGlass( $('.template_360 .zoom').position().left , $('.template_360 .zoom').position().top );

		});




	}*/

	function initKwicks(){
		if( $('.accordion.kwicks-horizontal').size()) {
			$(".accordion").kwicks({
				max: 400,
				spacing: 2,
				size: 990,
				behavior: 'menu'
			});
		}

	}
	function setZoomGlass(l,t){

		var zoom_w =  $('.template_360 .zoom').width(),
			zoom_h =  $('.template_360 .zoom').height();

		l += zoom_w / 2;
		t += zoom_h / 2;

		var max_w = $('.template_360').width(),
			max_h = $('.template_360').height();

		var rapp_w = 1800 / max_w,
			rapp_h = 933 / max_h;

		var newl = (l) * rapp_w - (zoom_w / 2),
			newt = (t) * rapp_h;

		$('.template_360 .zoom .glass').css({backgroundPosition: parseInt(-newl)+'px '+parseInt(-newt)+'px'});  //parseInt(-l*1.2)+'px '+parseInt(-t*1.25)+'px'});
	}



/*	if( $('.install_scrollnav').size() ){

		var nbox = $('.template_display').size();
		var htmlstr = '';

		for(var i = 1; i <= nbox; i++)
			htmlstr += '<a href="javascript:void(0);">'+ i +'</a>';

		htmlstr = '<div class="scrollnav">' + htmlstr + '</div>';

		for(var i = 0; i < nbox; i++){

			var curr = $('.sections .template_display:eq('+ i +')');
			curr.prepend( htmlstr );

			curr.find('.scrollnav a:eq('+ i +')').addClass('sel');
		}


		$('.scrollnav a').click(function(){
			$(document).scrollTo( $('.sections .template_display:eq('+ ($(this).text()-1) +')') , {
				duration: 500,
				offset: -130,
				/!*easing: 'easeInOutQuart'*!/
			});

		});

	}*/

	/*if( $('.template_display').size() ){
		$('.template_display .gallery').data('selcount', 1);


		$('.template_display .gallery .prev, .template_display .gallery .next').click(function(){

			var gallery = $(this).parents('.gallery');
			var curr = gallery.find('.outer .sel').size() ? gallery.find('.outer .sel') : gallery.find('.outer .item:first');
			var next = null;


			if( $(this).hasClass('next') && curr.next().size() ){
				next = curr.next();
				gallery.data('selcount', gallery.data('selcount') + 1 );
				gallery.find('.outer').stop().scrollTo( next , 500 );
			}

			if( $(this).hasClass('prev')  && curr.prev().size() ){
				next = curr.prev();
				gallery.data('selcount', gallery.data('selcount') - 1 );
				gallery.find('.outer').stop().scrollTo( next , 500 );
			}

			next.addClass('sel').siblings().removeClass('sel');
			gallery.find('.dots span:eq('+ (gallery.data('selcount')-1) +')').addClass('sel').siblings().removeClass('sel');


			if( next.next().size() )
				gallery.find('.next').show();
			else
				gallery.find('.next').hide();


			if( next.prev().size() )
				gallery.find('.prev').show();
			else
				gallery.find('.prev').hide();


		});


		$('.template_display .gallery').each(function(){

			var ni = $(this).find('.item').size();
			var dots = '';

			for(var i = 1; i <= ni; i++ ){
				//dots += '<a '+( i==1 ? 'class="sel"' : ''   )+' href="javascript:void(0);" rel="'+ i +'"></a> ';
				dots += '<span '+( i==1 ? 'class="sel"' : ''   )+'></span> ';
			}

			$(this).find('.dots').html(dots);
		});

		/!*$('.template_display .gallery .dots a').click(function(){

			var gallery = $(this).parents('.gallery');
			var sel = gallery.find('.item:eq('+ ( $(this).attr('rel') - 1 ) +')');

			$(this).addClass('sel').siblings().removeClass('sel');
			gallery.find('.outer').scrollTo( sel , 500 );
		});*!/



		$('.template_display .models .choose').mouseenter(function(){
			var bott = $(this).find('.button_orange').size();

			$(this).stop(true).animate({
				top: 360 - bott * 41
			},{
				duration: 500,
				/!*easing: 'easeOutExpo'*!/
			});

		}).mouseleave(function(){

			$(this).stop(true).animate({
				top: 360
			},{
				duration: 500,
				/!*easing: 'easeOutExpo'*!/
			});

		});

		$('.template_display .models .choose a').click(function(){

			var display = $(this).parents('.display');
			var sel = display.find('.photos .item:eq('+ ($(this).attr('rel')-1)  +')');
			var maxz = 0;

			display.find('.photos .item').each(function(){
				if( $(this).attr('z-index') > maxz )
					maxz = $(this).css('z-index');
			});

			sel.hide().css('z-index', maxz + 5);
			sel.siblings().css('z-index','1');
			sel.fadeIn();

			$(this).addClass('sel').siblings().removeClass('sel');
		});






	}*/

});

$(function () {
	var header = $('.main-menu-container'),
		scrollPrev = 0;
	$(window).scroll(function () {
		var scrolled = $(window).scrollTop();
		if (scrolled > 100 && scrolled > scrollPrev) {
			header.addClass('out');
		} else {
			header.removeClass('out');
		}
		console.log(scrolled);
		if (scrolled < 100) {
			header.removeClass('fix');
		} else {
			header.addClass('fix');
		}


		scrollPrev = scrolled;
	});
});