var _CREDIC_CALC = function(){
	var $this = {
		carPrice: 0,
		calcButtonSelect: '#calc_credit_button',
		calcButtonSelectText: '#calc_credit_button',
		amountCreditValueSelector: '.credit_sum_block .value',
		creditRateSelector: '.credit_percents_rate .value',
		creditMonthSelector: '.credit_period .value',
		creditMonthlyPaymentSelector: '.credit_monthly_payment .value',
		creditMonthRangeSelector: '.credit_estimated_period .value',
		errorTextSelector: '.credit_rorm_error_wrapper .credit_form_error_text',
		result_values: {},
		firstPay: 0,
		months: 0,
		fitstPaySelector: '#initial_fee_data',
		mothsSelector: '#years_credit_data',
		initCreditCalc: function(values){
			$this.extendValues(values);
			$this.initPrice();
			$this.initUiSliders();
			$this.initCalcButton();
		},
		extendValues: function(values){
			if (!(typeof(values)=="undefined"||typeof(values.calcButtonSelectText)=="undefined"||values.calcButtonSelectText=='')){
				$this.calcButtonSelectText = values.calcButtonSelectText;
			}

		},
		initPrice: function(){
			$this.carPrice = price;
		},
		programs: [
			{
				name: "Стандрартная программа",
				firstPay: 15,
				min_creditSum: 100000,
				min_cost: 280000,
				max_creditSum: 6500000,
				min_months: 12,
				max_months: 60,
				rate: 18
			},
			{
				name: "Авто дороже 1.5 млн",
				firstPay: 30,
				min_creditSum: 1000000,
				min_cost: 1500000,
				max_creditSum: 10000000,
				min_months: 12,
				max_months: 60,
				rate: 15.9
			}
		],
		current_minimal_program: {},
		initSliderPrice: function(){
			var priceSlider = document.getElementById('slider_price');
			var price_pips_values = [0, 37.5, 75, 100];//pips position
			var range_price_slider = {
				'min': [ $this.carPrice * 0.15 ],
				'37%': [ $this.carPrice * 0.20 ],
				'75%': [ $this.carPrice * 0.3 ],
				'max': [ $this.carPrice * 0.4 ]
			};
			noUiSlider.create(priceSlider, {
				animate: true,
				behaviour: 'tap',
				start: [ $this.carPrice * 0.15 ],
				connect: 'lower',
				range: range_price_slider,
				format: {
					to: function ( value ) {
						return fw_MAMMON.formattedNumber(Math.round(value));
					},
					from: function ( value ) {
						return value.replace(' ', '');
					}
				},
				pips: {
					mode: 'positions',
					density: 1000,
					values: price_pips_values,
					format: {
						to: function ( value ) {
							return Math.floor((value / $this.carPrice) * 100) + '%';
						},
						from: function ( value ) {
							var result = parseInt(value.replace('%', ''));
							return $this.carPrice * (result / 100);
						}
					}
				}
			});
			priceInput = $('#initial_fee_data');
			priceSlider.noUiSlider.on("update", function( values, handle ) {
				priceInput.val(values[handle]);
			});
			priceInput.on('change.slider', function(){
				priceSlider.noUiSlider.set([$(this).val()]);
			});
			priceSlider.noUiSlider.on('set', function(){
				/*var curState = self._states.getState(getCurrentInitialFee());
				 setYears(curState.getYearsLimit());
				 updateYearSlider();*/
			});
		},
		initSliderTerm: function(){
			var termSliderEl = document.getElementById('slider_years');
			var range_term_slider  = {
				'min': [ 12 ],
				'max': [ 60 ]
			};
			var year_pips_values = [12,24,36,48,60];
			noUiSlider.create(termSliderEl, {
				animate: true,
				behaviour: 'tap',
				start: [12],
				connect: 'lower',
				range: range_term_slider,
				format: {
					to: function ( value ) {
						return Math.round(value);
					},
					from: function ( value ) {
						return value;
					}
				},
				pips: {
					mode: 'values',
					density: 1000,
					values: year_pips_values
				}
			});
			var yearsInputEl = $('#years_credit_data');
			termSliderEl.noUiSlider.on("update", function( values, handle ) {
				var value = values[handle];
				yearsInputEl.val(value);
			});
			/*self._yearsInput.off('.slider');*/
			yearsInputEl.on('change.slider', function(){
				termSliderEl.noUiSlider.set([$(this).val()]);
			});

		},
		initUiSliders: function(){
			$this.initSliderPrice();
			$this.initSliderTerm();
		},
		initCalcButton: function(){
			$($this.calcButtonSelect).click(function(){
				$this.calcCredit();
				return false;
			});
		},
		calcCredit: function(){
			$this.getCalculationInitialValues();
			$this.calculateCredit();
			$this.showCalcError();

			if (!$this.isResultHasError()){
				$this.setCalculatedValues();
				$('#calc_results').show().removeClass('hidden');
				$this.changeButtonText();
				$('.fw_win_wrapper').scrollTo($('#calc_results'), 300, {offset:{top:-60}});
				$this.setCalculatedValuesToInputs();
			}
		},
		recalc: function(){
			$this.initPrice();
			$this.calcCredit();
		},
		isResultHasError: function(){
			if ($this.result_values.errrorText == '') return false;
			return true;
		},
		getCalculationInitialValues: function(){
			$this.firstPay = $this.getFirstPay();
			$this.months = parseInt($($this.mothsSelector).val());
		},
		showCalcError: function(){
			$($this.errorTextSelector).html($this.result_values.errrorText);
		},
		setCalculatedValues: function(){
			$($this.amountCreditValueSelector).html($this.result_values.credit_sum);
			$($this.creditRateSelector).html($this.result_values.rate);
			$($this.creditMonthSelector).html($this.result_values.month);
			$($this.creditMonthlyPaymentSelector).html(($this.result_values.monthlyPayment).split('.')[0] + ' <span class="rouble">руб.</span>');
			$($this.creditMonthRangeSelector).html($this.result_values.textMonthRange);
		},
		calculateCredit: function(){
			$this.result_values = _MA_CREDIT_CALCULATOIN.calculateAnnuityInCalc({firstPay: $this.firstPay, months: $this.months, cost: $this.carPrice, programs: $this.programs});
		},
		getFirstPay: function(){
			return parseInt($($this.fitstPaySelector).val().replace(/\s+/g, ''));
		},
		setCalculatedValuesToInputs: function(){
			$('input[name=period]').val($this.months);
			$('input[name=percentage]').val($this.result_values.rate);
			$('input[name=an_initial_fee]').val($this.firstPay);
			$('input[name=credit_summ]').val($this.result_values.origValues.credit_sum);
			$('input[name=monthly_payment]').val($this.result_values.monthlyPayment);
			$('input[name=full_percentage_summ]').val();
			$('input[name=estimated_period]').val($this.result_values.textMonthRange);
		},
		changeButtonText: function(){
			$($this.calcButtonSelectText).text('Пересчитать кредит');
		},
		getMinCreditConditions: function (price) {
			/**
			 * Получение минимальных кредитных условий
			 * функция временная, при переделке калькулятора выкинуть в ведро
			 * !!! используется на других сайтах
			 */
			this.firstPay = price * 0.4; // Макс значение, см this.initSliderPrice() // this.carPrice;
			this.carPrice = price;
			this.months = 60; // Макс значение, см this.initSliderTerm()
			this.calculateCredit();

			return this.result_values;
		}
	};
	return $this;
}();