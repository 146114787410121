var fw_MAMMON = function(){
	if(typeof(fw_MAMMON) !== "undefined") return fw_MAMMON;
	var $mammon = {};

	var checkSmiles = function(value) {
		var utf8mb4reg = /[\uD83C-\uDBFF\uDC00-\uDFFF]+/;
		return utf8mb4reg.test(value);
	};
	
	var check = function(value, type, item){
		var reg = false;
		//приводим к строке
		if (value !== undefined && !isNaN(value) && value !== null) value = value + "";
		if (value === null) value = '';
		switch(type){
			case 'ne':
				if($.empty($.trim(String(value)))) return false;
				break;
			case 's': // isset
			case 'c': // checkbox
				if(value === undefined) return false;
				break;
			case 'i'://integer
				if(value === undefined || isNaN(value*1)) return false;
				break;
			case 'a':
				if(value === undefined || typeof value !== "object") return false;
				break;
			case 'wene':
				if (value === undefined || $.empty(value))
					return false;
				break;
			case 'mail_list':
				if (value === undefined) return false;
				var tmp = value.split(',');
				reg = /^.+@.+\..+$/;
				for(var i in tmp){
					if (checkSmiles(tmp[i])) return false;
					if(!reg.test(tmp[i])) return false;
				}
				reg = false;
				break;
			case 'regno':
				if (value === undefined) return false;
				var translate_to_rus = {'A' : 'А','B':'В','E':'Е','K':'К','M':'М','H':'Н','O':'О','P':'Р','C':'С','T':'Т','Y':'У','X':'Х'};
				value = value.toUpperCase();
				for (var en in translate_to_rus){
					value = value.replace('/'+en+'/g', translate_to_rus[en]);
				}
				if(/[^0-9АВЕКМНОРСТУХ]/.test(value) || !/([^\d])([\d]{3})([^\d]+)([\d]{2,3})/.test(value)) return false;
				break;
			case 'fl':
				reg = /^(?:\-?\d+([\.||,]\d{0,})?)?$/;
				break;
			case 'code':
				reg = /^[a-z_0-9]+$/;
				break;
			case 'mail':
				if (checkSmiles(value)) return false;
				value = value.toLowerCase();
				reg = /.+@.+\..+/i;
				break;
			case 'd':
				reg = /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/;
				break;
			case 'ph':
                reg = /^\+?([0-9- ]{0,5})?([(|-][0-9- ]{0,6}[)|-])?[0-9- ]{5,10}$/;
				break;
			case 'vin':
				value = value.toUpperCase();
				reg = /^[0-9A-HJ-NPR-Z]+$/;
				break;
			case 'reg':
				reg = item.pattern;
				break;
			case 'rus':
				return !(/[^а-яё\-\ ]/i.test(value));
				break;
		}
		if(reg && (value == undefined || value.search(reg) == -1)){
			return false;
		}
		return true;
	};
	 
	$mammon = {
		// Доп функция для определения ширины скролла
		getScrollWidth: function(){
			// Заводим окружение
			var scrollWidth;
			// Возвращаем функцию
			return function(){
				if(scrollWidth === undefined){
					var testContent = $("<div>").html("m</br>r<br/>F</br>l<br/>e</br>s</br>h</br>k</br>a");
					var testScroll = $('<div>').css({
						overflowY: "scroll",
						position: "absolute",
						width: 50,
						height: 40,
						left: 0,
						top: -40
					}).append(testContent);
					testScroll.appendTo($("body:first"));
					scrollWidth = Math.max(0, testScroll.width() - testContent.width());
					testScroll.remove();
				}
				return scrollWidth;
			}
		}(),
		fixBodyScroll: function(){
			var $body_scroll_fix = {};
			var default_options = {
				fix_class: "body_scroll_fix"
			};
			var min_site_width = 0;
			var lastWidth = 0;
			var classes_list = {};
			var has_resize_event = false;

			function setMinSiteWidth(site_width){
				min_site_width = site_width;
			}
			function addToClassesCollection(new_class){
				classes_list[new_class] = "."+new_class;
			}
			function getCollection(){
				var classes_selector = "";
				for(var class_selector in classes_list){
					if(classes_list.hasOwnProperty(class_selector)){
						classes_selector += classes_list[class_selector]+",";
					}
				}
				classes_selector = classes_selector.substr(0, classes_selector.length - 1);
				var collection = $(classes_selector);
				if(collection.length == 0) return false;
				return collection;
			}
			
			function updateBodyFixScrollWidth(){
				var collection = getCollection();
				if(collection){
					var newWidth = Math.max(parseInt(window.innerWidth)||0, parseInt(document.documentElement.clientWidth)||0) - fw_MAMMON.getScrollWidth();
					if(newWidth < min_site_width){
						newWidth = min_site_width;
					}
					if(newWidth !== lastWidth){
						lastWidth = newWidth;
						collection.css({width:newWidth});
					}
				}
			}
			
			function initResizeEvent(){
				if(!has_resize_event){
					has_resize_event = true;
					$(window).bind('resize.fix_body_scroll', function(){
						updateBodyFixScrollWidth();
					});
				}
			}
			
			$body_scroll_fix = {
				setNewFix: function(custom_options){
					// Ненадо ничего фиксить, где скролл не изменяет ширину
					if(fw_MAMMON.getScrollWidth() == 0) return;
					var options = $.extend({}, default_options, custom_options);
					// Добавляем новый класс в коллекцию классов
					addToClassesCollection(options.fix_class);
					// Вешаем событие на ресайз
					initResizeEvent();
					// Запускаем первый раз сразу для обработки имеющихся блоков
					updateBodyFixScrollWidth();
				},
				setMinWidth: setMinSiteWidth,
				doFix: updateBodyFixScrollWidth
			};
			return $body_scroll_fix;
		}(),
		objectsEquals: function(first_obj, second_obj, func){
			if(fw_MAMMON.objCount(first_obj) != fw_MAMMON.objCount(second_obj)) return false;
			for(var key in first_obj){
				if(first_obj.hasOwnProperty(key)){
					if(!second_obj[key]) return false;
					if(func && typeof(func) == "function"){
						if(!func(first_obj[key], second_obj[key])){
							return false;
						}
					}
				}
			}
			return true;
		},
		objCount:function(obj){
			var count = 0;
			for (var k in obj) if (obj.hasOwnProperty(k)) ++count;
			return count;
		},
		setOnBeforeUnload:function(message){
			window.onbeforeunload = function (evt) {
				if (typeof evt == "undefined") {
					evt = window.event;
				}
				if (evt) {
					evt.returnValue = message;
				}
				return message;
			}
		},
		clearOnBeforeUnload:function(){
			window.onbeforeunload = function(){};
		},
		// Иногда нужно!
		serializeObj:function(selector){
			var serializedObj = {};
			$(selector).find("input[type!='checkbox'][type!='radio'],select,textarea,input[type='checkbox']:checked,input[type='radio']:checked").each(function(){
				if(!$.empty($(this).attr("name"))){
					serializedObj[$(this).attr("name")] = $(this).val();
				}
			});
			return serializedObj;
		},
		smartTouchHover: function(){
			$(document).on("touchstart.smart_touch", ".smart_touch", function(){
				$(this).addClass('tapped');
			});
			$(document).on("touchend.smart_touch", ".smart_touch", function(){
				$(this).removeClass('tapped');
			});
		},
		serializeObjSmart:function(selector){
			var serializedObj = {};
			$(selector).find("input[type!='checkbox'][type!='radio'],select,textarea,input[type='checkbox']:checked,input[type='radio']:checked").each(function(){
				if(!$.empty($(this).attr("name"))){
					var name = $(this).attr("name");
					if(/^.*?\[.*?\]$/.test(name)){
						var array_name = name.replace(/^(.*?)\[.*?\]$/, "$1");
						var array_key = name.replace(/^.*?\[(.*?)\]$/, "$1");
						if(!serializedObj.hasOwnProperty(array_name)){
							serializedObj[array_name] = $.empty(array_key) ? [] : {};
						}
						if($.empty(array_key)){
							serializedObj[array_name].push($(this).val());
						}
						else{
							serializedObj[array_name][array_key] = $(this).val();
						}
					}
					else{
						serializedObj[name] = $(this).val();
					}
				}
			});
			return serializedObj;
		},
		serializePost:function(selector){
			var serializedArray = [];
			$(selector).find("input[type!='checkbox'][type!='radio'],select,textarea,input[type='checkbox']:checked,input[type='radio']:checked").each(function(){
				if(!$.empty($(this).attr("name"))){
					serializedArray[serializedArray.length] = {"name":$(this).attr("name"),"value":$(this).val()};
				}
			});
			return serializedArray;
		},
		serializePostWithCKEditor:function(selector){
			var serializedArray = [];
			$(selector).find("input[type!='checkbox'][type!='radio'],select,input[type='checkbox']:checked,input[type='radio']:checked").each(function(){
				if(!$.empty($(this).attr("name"))){
					serializedArray[serializedArray.length] = {"name":$(this).attr("name"),"value":$(this).val()};
				}
			});
			// Отдельно процессим текстареа:
			$(selector).find("textarea").each(function(){
				var $textarea = $mammon.processCKEditor($(this));
				if(!$.empty($($textarea).attr("name"))){
					serializedArray[serializedArray.length] = {"name":$($textarea).attr("name"),"value":$($textarea).val()};
				}
			});
			return serializedArray;
		},
		processCKEditor:function(textarea){
			//Проверяем глобальный объект CKEDITOR на странице.
			if(typeof(CKEDITOR) != "undefined"){
				//Собираем все textarea в контейнере, пробуем найти их в CKEDITOR.instances 
				var id = textarea.attr("id");
				if(CKEDITOR.instances[id] != undefined){
					CKEDITOR.instances[id].updateElement();
				}
			}
			return textarea;
		},
		serializeArr:function(selector){
			var serializedArray = [];
			$(selector).find("input[type!='checkbox'][type!='radio'],select,textarea,input[type='checkbox']:checked,input[type='radio']:checked").each(function(){
				if($(this).attr("name") != undefined){
					serializedArray[serializedArray.length] = $(this).val();
				}
			});
			return serializedArray;
		},
		getPostArray:function(obj){
			var newArray = [];
			for(var key in obj){
				if(obj.hasOwnProperty(key)){
					var tmpObj = (typeof(obj[key]) === 'object') ? obj[key] : {'name': key, 'value': obj[key]};
					newArray = newArray.concat(tmpObj);
				}
			}
			return newArray;
		},
		getObjFromPost:function(post_data){
			var newObj = {};
			for(var i=0; i < post_data.length; i++){
				if(post_data[i].name === undefined) continue;
				var name = post_data[i].name.replace(/\[.*\]/,"");
				// Проверяем имя на массив.
				var value = post_data[i].value;
				if(post_data[i].name.match(/\[.*\]/)){
					var in_bracers = post_data[i].name.replace(/.*\[(.*)\]/,"$1");
					if(newObj[name] == undefined){
						newObj[name] = (in_bracers === "")?[]:{};
					}
					newObj[name][(in_bracers === "")?newObj[name].length:in_bracers] = value;
				}
				else{
					newObj[name] = value;
				}
			}
			return newObj;
		},
		preventHref: function(event) {
			if(event.preventDefault){
				event.preventDefault();
			}
		},
		preventDefaultSafe: function(event) {
			if(!event){
				event = window.event;
			}
			if(event.stopPropagation){
				event.stopPropagation();
			}
			else{
				event.cancelBubble = true;
			}
			if(event.preventDefault){
				event.preventDefault();
			}
			else{
				event.returnValue = false;
			}
		},
		formattedNumber: function(value, precision){
			var val = parseFloat(value);
			precision = isFinite(precision) ? precision : 0;
			var valueStr = String(val.toFixed(precision)).reverse();
			valueStr = $.trim((valueStr.replace(/(([0-9]*\.)?[0-9]{3})/g, '$1 ')).reverse());
			return valueStr;
		},
		formattedRegNo: function(regNo){
			var replacementSimbol = {
				'А': 'A',
				'В': 'B',
				'Е': 'E',
				'К': 'K',
				'М': 'M',
				'Н': 'H',
				'О': 'O',
				'Р': 'P',
				'С': 'C',
				'Т': 'T',
				'У': 'Y',
				'Х': 'X',
				'Б': 'M',
				'а': 'A',
				'в': 'B',
				'е': 'E',
				'к': 'K',
				'м': 'M',
				'н': 'H',
				'о': 'O',
				'р': 'P',
				'c': 'C',
				'т': 'T',
				'у': 'Y',
				'х': 'X',
				'б': 'M'
			};
			if($.empty(regNo)) return "";
			return (regNo.strtr(replacementSimbol)).replace(/([^\d])([\d]+)([^\d]+)([\d]+)/, '<span class="number">$1&thinsp;$2&thinsp;$3</span><span class="region">$4</span>');
		},
		formattedSimpleRegNo: function(regNo){
			if($.empty(regNo)) return "Не указан";
			return regNo.replace(/([^\d])([\d]+)([^\d]+)([\d]+)/, '$1 $2 $3 $4');
		},
		addSlashes: function(str) {
			str = str.replace(/\\?("|')/g, '\\$1');
			return str;
		},
		removeQuotes: function(str) {
			str = str.replace(/("|')/g, '');
			return str;
		},
		writeStrToCopyContainer:function(include_str){
			var copy_input = $('<input class="block_to_clipboard_input" type="text" value=""/>').val(include_str);
			var copy_container = $('<div class="block_to_clipboard"></div>').html(copy_input);
			var copy_overlay = $('<div class="block_to_clipboard_overlay"></div>');
			var remove_hook = function(){
				$(".block_to_clipboard,.block_to_clipboard_overlay").animate({opacity:0},200,function(){
					$(this).remove();
				});
			};
			copy_input.bind("keyup",remove_hook);
			copy_overlay.click(remove_hook);
			$("body:first").append(copy_overlay).append(copy_container);
			copy_input.select();
		},
		isjQueryObj:function(obj){
			return obj instanceof jQuery;
		},
		getNextPropertyInObj:function(current_property_key, obj) {
			var getProperty = false;
			for (var key in obj) {
				if (obj.hasOwnProperty(key)) {
					if (getProperty) return obj[key];
					if (key == current_property_key) {
						getProperty = true;
					}
				}
			}
			return false;
		},
		getDayOfWeek:function(date_variable){
			var days = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];
			return days[date_variable.getDay()];
		},
		escapeHtml:function(unsafe){
			if(typeof(unsafe) == "string"){
				return unsafe
					.replace(/&/g, "&amp;")
					.replace(/</g, "&lt;")
					.replace(/>/g, "&gt;")
					.replace(/"/g, "&quot;")
					.replace(/'/g, "&#039;");
			}
			return unsafe;
		},
		/**
		 * Валидирует список значений.
		 * @param values assoc array[
		 * 		{name1 : value1},
		 * 		...
		 * 		{name2 : value2}
		 * ]
		 * @param validations array of objects [
		 * 		name1 : {
		 *     		type : 'i:ne', //httpDataLib type
		 *			message : 'errmessage1'
		 *			pattern : regExp //если type = 'reg'
		 * 		},
		 * 		...
		 * 		name2 : {
		 *     		type : type2,
		 *			message : 'errmessage2'
		 * 		}...
		 * 	]
		 * @returns {boolean} || {array}
		 */
		validateAll : function(form_fields, validations){
			var i, j,
				errors = {};
			for(i in validations){
				var types = validations[i].type.split(':');
				for(j = 0; j < types.length ; j++){
					if(!check(form_fields[i], types[j], validations[i])){
						errors[i] = validations[i];
					}
				}
			}
			return $.empty(errors) ? false : errors;
		},
		/**
		 * Валидирует одно значение
		 * @param value
		 * @param item object {type : 'i:ne', message : 'errmessage1', pattern : regExp}
		 * @returns {boolean} || {array}
		 */
		validate : function(value, item){
			var types = item.type.split(':'),
				j,
				errors = [];
			for(j = 0; j < types.length ; j++){
				if(!check(value, types[j], item)){
					errors.push(item);
				}
			}
			return $.empty(errors) ? false : errors;
		},
		// Функция имеет право дописываться.
		markBodyByDevice: function(){
			if($mammon.isiPhone()){
				$("html:first").addClass("is_iphone");
			}
		},
		isMobileDevice : function() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|touch/i.test(navigator.userAgent);
		},
		isMobileDeviceWithoutViewPort : function() {
			return $mammon.isMobileDevice() && $("meta[name=viewport]").length == 0;
		},
		isiPhone : function() {
			return /iPhone/i.test(navigator.userAgent);
		},
		isiPad : function() {
			return /iPad/i.test(navigator.userAgent);
		},
		lteIE7 : function() {
			return document.all && !document.querySelector;
		},
		isIE7 : function() {
			return navigator.appVersion.indexOf("MSIE 7.") != -1;
		},
		isIE8 : function() {
			return (navigator.userAgent.indexOf('IE 8') != -1);
		},
		isIE : function() {
			return /MSIE (\d+\.\d+);|Trident\/7.0/.test(navigator.userAgent);
		},
		getEnglishDateString:function(str) {
			var monthes = {
				"Январь":"January",
				"Февраль":"February",
				"Март":"March",
				"Апрель":"April",
				"Август":"August",
				"Сентябрь":"September",
				"Октябрь":"October",
				"Ноябрь":"November",
				"Декабрь":"December"
			};
			for (var val in monthes) {
				str = str.replace(val,monthes[val]);
			}
			return str;
		},
		morph: function(value, word0, word1, word2){
			if(/1\d$/.test(value)){
				return word2;
			}
			else if(/1$/.test(value)){
				return word0;
			}
			else if(/(2|3|4)$/.test(value)){
				return word1;
			}
			else{
				return word2;
			}
		},
		getFirstZIndexFromParents: function(obj){
			var parents = $(obj).parents(),
				zIndex = 0;
			parents.each(function(){
				if(this.style && this.style.zIndex){
					zIndex = this.style.zIndex | 0;
					return false;
				}
			});
			return zIndex;
		},
		getPhoneBody: function(phone, country_code){
			return "" + (country_code || "+7") + phone.replace(/[\D]/g, "");
		}
	};
	return $mammon;
}();