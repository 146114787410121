(function($){
	"use strict";
	var MIN_IN_HOUR = 60;
	var CHECK_TIME_REGEXP = "^(2[0-3]|[01]?[0-9]):([0-5][0-9])$";

	var CoreFunctions = function(){
		var check_time_regexp = new RegExp(CHECK_TIME_REGEXP);
		function parseTime(date_string, group_index){
			return date_string.replace(check_time_regexp,'$' + group_index) | 0;
		}
		var $core_functions = {
			parse: {
				hour: function(date_string){
					return parseTime(date_string, 1);
				},
				min: function(date_string){
					return parseTime(date_string, 2);
				}
			},
			get: {
				formattedMin: function(min){
					return min < 10 ? '0' + min : min;
				},
				formattedHour: function(hour, forwardZeroHour){
					return (forwardZeroHour && hour < 10) ? '0' + hour : hour;
				},
				intEquivalentTime: function(time){
					return $core_functions.parse.hour(time) * MIN_IN_HOUR + $core_functions.parse.min(time);
				},
				firstZIndexFromParents: fw_MAMMON ? fw_MAMMON.getFirstZIndexFromParents : function(obj){
					var parents = $(obj).parents(),
						zIndex = 0;
					parents.each(function(){
						if(this.style && this.style.zIndex){
							zIndex = this.style.zIndex | 0;
							return false;
						}
					});
					return zIndex;
				}
			},
			check: {
				timeParams: function(bhour, bmin, ehour, emin, currentStep){
					if((bhour > ehour) ||
						(bhour === ehour && bmin > emin) ||
						(bhour > 24 || bhour < 0 || ehour > 24 || ehour < 0) ||
						(bmin > 59 || bmin < 0 || emin > 59 || emin < 0) ||
						currentStep <= 0){
						throw "Неверные входные параметры времени";
					}
					return true;
				},
				time: function(time){
					return check_time_regexp.test(time);
				}
			},
			action: {
				cancelEvent: function(e){
					e = e || window.event;
					if(e.stopPropagation){
						e.stopPropagation();
					}
					else{
						e.cancelBubble = true;
					}
					if(e.preventDefault){
						e.preventDefault();
					}
					else{
						e.returnValue = false;
					}
				}
			}
		};
		return $core_functions;
	};
	var CORE_FUNC;
	var DEFAULT_OPTIONS = {
		headers: ['Утро', 'День', 'Вечер'],
		minTime: "9:00",
		maxTime: "20:30",
		defaultTime: null,
		stepTime: 30,
		countColumn: 0,
		forwardZeroHour: false,
		easing: "swing",
		duration: 300,
		bgPositionClasses: {
			common: 'tp-bg-common',
			hover: 'tp-bg-hover',
			active: 'tp-bg-active'
		},
		beforeShow: function(){},
		afterShow: function(){},
		onSelectTime: function(){}
	};
	var currentTimePickerIndex = 0;

	var InstanceTimePicker = function(target_input, user_options, disabled_time){
		CORE_FUNC = CORE_FUNC || new CoreFunctions();
		
		var options = $.extend(true, {}, DEFAULT_OPTIONS, user_options);
		var times = [];
		var active_style_class = "";
		var is_visible = false;
		var events_namespace = "time_picker_" + currentTimePickerIndex;
		var current_time_prefix_id = "timepicker_" + currentTimePickerIndex;
		var unique_identifier = "time_picker_" + currentTimePickerIndex++;
		var local_functions = function(){
			var $local_functions;
			$local_functions = {
				tryDisableTime: function(disabled_time){
					(typeof(disabled_time) === "object") && $local_functions.disabledMoreTime(disabled_time);
				},
				disabledMoreTime: function(disabled_time){
					if(disabled_time && typeof(disabled_time) === "object"){
						var disable_time = $local_functions.disableTimeArray(disabled_time);
						for(var i = 0; i < disable_time.length; i++){
							$local_functions.disabledTimeByStr(Math.floor(disable_time[i] / MIN_IN_HOUR) + ":" + disable_time[i] % MIN_IN_HOUR);
						}
					}
				},
				disableTimeArray: function(disabled_time){
					var disableTime = [];
					var min_dis_time = 0;
					var max_dis_time = 0;
					var i;
					var source_time;
					if(!disabled_time){
						return disableTime;
					}
					if(disabled_time.hasOwnProperty('time') && $.isArray(disabled_time.time)){
						for(i = 0; i < disabled_time.time.length; i++){
							disableTime.push(CORE_FUNC.get.intEquivalentTime(disabled_time.time[i]));
						}
					}
					source_time = (typeof(disabled_time.from_time) === "undefined" || disabled_time.from_time === 0) ? options.minTime : disabled_time.from_time;
					min_dis_time = CORE_FUNC.get.intEquivalentTime(source_time);
					source_time = (typeof(disabled_time.to_time) === "undefined" || disabled_time.to_time === 0) ? options.maxTime : disabled_time.to_time;
					max_dis_time = CORE_FUNC.get.intEquivalentTime(source_time);
					for(i in times){
						if(times.hasOwnProperty(i)){
							if(max_dis_time < times[i]){
								break;
							}
							if(min_dis_time <= times[i] && $.inArray(times[i], disableTime) === -1){
								disableTime.push(times[i]);
							}
						}
					}
					return disableTime;
				},
				disabledTimeByStr: function(time){
					var dTime = CORE_FUNC.get.formattedHour(CORE_FUNC.parse.hour(time), options.forwardZeroHour) + '_' + CORE_FUNC.get.formattedMin(CORE_FUNC.parse.min(time));
					return $local_functions.disableTime(dTime);
				},
				disableTime: function(time_string){
					return ($('#' + current_time_prefix_id + '_' + time_string).addClass('disabled_timepicker').length !== 0);
				},
				disabledTimeByInt: function(time){
					var dTime = CORE_FUNC.get.formattedHour(Math.floor(time / MIN_IN_HOUR), options.forwardZeroHour) + '_' + CORE_FUNC.get.formattedMin(time % MIN_IN_HOUR);
					$local_functions.disableTime(dTime);
				},
				disableUntilTime: function(time){
					var time_int = CORE_FUNC.get.intEquivalentTime(time);
					var selected_time_int = CORE_FUNC.get.intEquivalentTime($(target_input).val());
					for(var i in times){
						if(times.hasOwnProperty(i)){
							if(times[i] > time_int) break;
							$local_functions.disabledTimeByInt(times[i]);
						}
					}
					(selected_time_int <= time_int) && target_input.val('');
				},
				clearDisabled: function(){
					html_element.find('.disabled_timepicker').removeClass('disabled_timepicker');
				},
				HTMLFragment: function(){
					var bhour = CORE_FUNC.parse.hour(options.minTime);
					var bmin  = CORE_FUNC.parse.min(options.minTime);
					var ehour = CORE_FUNC.parse.hour(options.maxTime);
					var emin  = CORE_FUNC.parse.min(options.maxTime);
					var current_step = options.stepTime | 0;
					var html_fragment;
					CORE_FUNC.check.timeParams(bhour, bmin, ehour, emin, current_step);
					
					var inline_text = '<div class="container_timepicker shadow_timepicker"><table class="table_timepicker">';
					var count_cell = (((ehour * MIN_IN_HOUR + emin) - (bhour * MIN_IN_HOUR + bmin))/current_step + 1) | 0;
					var count_block = options.headers.length;
					var count_cell_in_block = [];
					var count_column = 0;
					var tr_time_picker = [''];
					var current_block = 0;
					var header_row_index = 0;
					var delimiter_row_index = 1;
					var i = 0;
					
					if (count_cell < count_block) throw "Количество ячеек меньше количества блоков";
					
					while(count_cell > 0){
						count_cell_in_block[i] = parseInt(count_cell / count_block) + (count_cell % count_block !== 0);
						count_cell = count_cell - count_cell_in_block[i++];
						count_block--;
					}
					count_block = options.headers.length;
					
					while(current_block < count_block){
						count_column = $local_functions.getCountColumn(count_cell_in_block[current_block]);
						var count_row = parseInt(count_cell_in_block[current_block] / count_column) + (count_cell_in_block[current_block] % count_column !== 0);
						
						tr_time_picker[header_row_index] = tr_time_picker[header_row_index] || '';
						tr_time_picker[delimiter_row_index] = tr_time_picker[delimiter_row_index] || '';
						if(current_block === 0){
							tr_time_picker[header_row_index] += '<tr class="header_timepicker">';
							tr_time_picker[delimiter_row_index] += '<tr class="row_delimiter">';
						}
						tr_time_picker[header_row_index] +=
							'<td colspan="' + count_column +
							'" class="cell_header_timepicker"><a href="#" onclick="return false" class="1' +
							(current_block !== count_block - 1 ? " cell_spaces" : "") + '">' +
							options.headers[current_block] + '</a></td>';
						tr_time_picker[delimiter_row_index] += '<td colspan="' + count_column + '" class="cell_delimiter"></td>';
						
						var current_row_in_table = 2;
						var current_row = 0;
						var current_cell = 0;
						while(current_row <= count_row){
							tr_time_picker[current_row_in_table] = tr_time_picker[current_row_in_table] || '';
							
							if(current_block === 0){
								tr_time_picker[current_row_in_table] += '<tr class="content_timepicker">';
							}
							
							var current_column = 0;
							while(current_column < count_column){
								if(current_cell < count_cell_in_block[current_block]){
									times.push(bhour * MIN_IN_HOUR + bmin);
									tr_time_picker[current_row_in_table] +=
										'<td id="' + current_time_prefix_id + '_' + CORE_FUNC.get.formattedHour(bhour, options.forwardZeroHour) +
										'_' + CORE_FUNC.get.formattedMin(bmin) + '" class="cell_timepicker"><a href="#" onclick="return false" class="' +
										(current_column === count_column-1 && current_block !== count_block - 1? " cell_spaces" : "") + '">' +
										CORE_FUNC.get.formattedHour(bhour, options.forwardZeroHour) + ':' + CORE_FUNC.get.formattedMin(bmin) + '</a></td>';
									current_cell++;
									bmin += current_step;
									if(bmin >= MIN_IN_HOUR){
										bhour += Math.floor(bmin / MIN_IN_HOUR);
										bmin = bmin % MIN_IN_HOUR;
									}
								}
								else{
									tr_time_picker[current_row_in_table] += '<td id="" class="cell_timepicker"></td>';
								}
								current_column++;
							}
							if(current_block === count_block - 1){
								tr_time_picker[current_row_in_table] += '</tr>';
							}
							current_row++;
							current_row_in_table++;
						}
						if(current_block === count_block - 1){
							tr_time_picker[header_row_index] += '</tr>';
							tr_time_picker[delimiter_row_index] += '</tr>';
						}
						current_block++;
					}
					inline_text += tr_time_picker.join('');
					inline_text += '</table></div>';
					html_fragment = $(inline_text);
					$('body').append(html_fragment);
					return html_fragment;
				},
				showTimePicker: function(){
					if(is_visible) return;
					timePickerManager.hideAllTimePickers();
					$local_functions.execCallback('beforeShow');
					is_visible = true;
					$local_functions.changePosition();
					html_element.show(options.duration, options.easing, function(){
						$local_functions.execCallback('afterShow');
					});
					$local_functions.setStyleActive();
				},
				hideTimePicker: function(){
					if(!is_visible) return;
					is_visible = false;
					html_element.hide(options.duration, options.easing);
					$local_functions.setStyleCommon();
				},
				changePosition: function(){
					html_element.css({
						top: $(target_input).offset().top + $(target_input).height(),
						left: $(target_input).offset().left,
						zIndex: CORE_FUNC.get.firstZIndexFromParents(target_input)
					});
				},
				setStyle: function(code){
					$(target_input).removeClass(active_style_class);
					if(options.bgPositionClasses[code]){
						active_style_class = options.bgPositionClasses[code];
						$(target_input).addClass(active_style_class);
					}
				},
				setStyleCommon: function(){
					!is_visible && $local_functions.setStyle('common');
				},
				setStyleHover: function(){
					!is_visible && $local_functions.setStyle('hover');
				},
				setStyleActive: function(){
					$local_functions.setStyle('active');
				},
				destroyTimePicker: function(){
					html_element.unbind();
					html_element.find('.cell_timepicker').unbind();
					$(target_input).unbind();
					$(window).unbind('.' + events_namespace);
					$(document).unbind('.' + events_namespace);
					html_element.remove();
					timePickerManager.removeInstance(instance);
				},
				execCallback: function(callback){
					(typeof(options[callback]) === "function") && options[callback](Array.prototype.slice.call(arguments, 1));
				},
				selectTime: function(obj){
					if(!$(obj).hasClass('disabled_timepicker')){
						html_element.find('.selected_timepicker').removeClass('selected_timepicker');
						$(obj).addClass('selected_timepicker');
						$(target_input).val($(obj).text());
						$local_functions.execCallback('onSelectTime', $(obj).text());
						$local_functions.hideTimePicker();
					}
				},
				setDefaultTime: function(){
					if(options.defaultTime && CORE_FUNC.check.time(options.defaultTime)){
						var dTime = CORE_FUNC.get.formattedHour(CORE_FUNC.parse.hour(options.defaultTime), options.forwardZeroHour) + '_' + CORE_FUNC.get.formattedMin(CORE_FUNC.parse.min(options.defaultTime));
						$local_functions.selectTime($('#' + current_time_prefix_id + '_' + dTime));
					}
				},
				addEvents: function(){
					html_element.find('.cell_timepicker').bind('click', function(e){
						$local_functions.selectTime($(this));
						CORE_FUNC.action.cancelEvent(e);
					});
					$(target_input)
						.bind('click', function(e){
							if($(this).val() !== html_element.find('.selected_timepicker').text()){
								html_element.find('.selected_timepicker').removeClass('selected_timepicker');
							}
							if($(this).val() !== ''){
								html_element.find('#' + $(this).val().replace(/:/,'_')).addClass('selected_timepicker');
							}
							html_element.css('display') === 'none' ? $local_functions.showTimePicker() : $local_functions.hideTimePicker();
							CORE_FUNC.action.cancelEvent(e);
						})
						.bind('mouseenter', $local_functions.setStyleHover)
						.bind('mouseleave', $local_functions.setStyleCommon);
					html_element
						.bind('click', CORE_FUNC.action.cancelEvent)
						.bind('mouseenter', $local_functions.setStyleActive);
					$(window).bind('resize.' + events_namespace, function(){
						if($(target_input).attr('id') !== undefined && html_element.css('display') === 'block'){
							$local_functions.changePosition();
						}
					});
					$(document).bind('click.' + events_namespace, $local_functions.hideTimePicker);
				},
				getUniqueIdentifier: function(){
					return unique_identifier;
				},
				getCountColumn: function(count_cell_in_block){
					var count_column = options.countColumn | 0;
					if(!count_column){
						var j = 2;
						while(count_cell_in_block / j > j){
							if(count_cell_in_block % j === 0){
								count_column = j;
							}
							j++;
						}
						count_column = count_column || (j > count_cell_in_block ? count_cell_in_block : j);
					}
					return count_column;
				}
			};
			return $local_functions;
		}();
		var html_element = local_functions.HTMLFragment(options, current_time_prefix_id);
		
		local_functions.tryDisableTime(disabled_time);
		local_functions.addEvents();
		local_functions.setDefaultTime();
		local_functions.setStyleCommon();
		
		var instance = {
			element: html_element,
			show: local_functions.showTimePicker,
			hide: local_functions.hideTimePicker,
			destroy: local_functions.destroyTimePicker,
			disabledUntilTime: local_functions.disableUntilTime,
			clearDisabled: local_functions.clearDisabled,
			getUniqueIdentifier: local_functions.getUniqueIdentifier,
			isVisible: function(){
				return is_visible;
			}
		};
		return instance;
	};

	var timePickerManager = function(){
		var $timepicker_manager;
		var instances = {};
		$timepicker_manager = {
			getInstance: function(target_input, user_options, disabled_time, timepicker_data_key){
				try{
					var instance = new InstanceTimePicker(target_input, user_options, disabled_time);
					instances[instance.getUniqueIdentifier()] = instance;
					$(target_input).data(timepicker_data_key || "timepicker", instance);
					return instance;
				}
				catch(e){
					console.error(e);
				}
			},
			removeInstance: function(instance){
				var unique_identifier = instance.getUniqueIdentifier();
				if(instances.hasOwnProperty(unique_identifier)){
					delete instances[unique_identifier];
				}
			},
			removeAllInstances: function(){
				for(var unique_identifier in instances){
					if(instances.hasOwnProperty(unique_identifier)){
						instances[unique_identifier].destroy();
					}
				}
			},
			hideAllTimePickers: function(){
				for(var unique_identifier in instances){
					if(instances.hasOwnProperty(unique_identifier)){
						instances[unique_identifier].hide();
					} 
				}
			}
		};
		return $timepicker_manager;
	}();

	$.timepicker = {
		hideAll: timePickerManager.hideAllTimePickers,
		removeAll: timePickerManager.removeAllInstances
	};

	$.fn.timepicker = function(param_options, disabled_time, timepicker_data_key){
		if($(this).length === 1){
			return timePickerManager.getInstance(this, param_options, disabled_time, timepicker_data_key);
		}
		else{
			var instances = [];
			$(this).each(function(){
				instances.push(timePickerManager.getInstance(this, param_options, disabled_time, timepicker_data_key));
			});
			return instances;
		}
	};
})(jQuery);