var fw_WIN = function(){
	var $fw_win = {};

	// Стандартный набор опций для окна.
	// Все опции могут быть переопределены.
	// Набор опций будет дополняться.
	var default_options = {
		use_scroll_fix: true,
		lock_page: false,
		block_mode: false,
		fixed_height: false,
		top_offset: 40,
		offset: 40,
		width: "",
		height: "auto",
		content: "",
		title: "Заголовок",
		fix_scroll_class: "fwin_body_scroll_fix",
		content_class: "",
		container_class: "",
		overlay_class: "",
		window_class: "",
		without_padding: false
	};

	// Все описанные на данный момент в системе колбэки.
	// Набор колбэков будет пополняться.
	var default_callbacks = {
		onBeforeClose: null,
		onAfterClose: null,
		onBeforeOpen: null,
		onAfterOpen: null,
		onAfterResize: null
	};

	// Набор опций для управляющего объекта
	var main_options = {
		//Подготовленные шаблоны элементов окна
		title_tpl:             '<div class="fw_win_title">',
		close_but_tpl:         '<div class="fw_win_close_button">',
		fixer_tpl:             '<div class="fw_win_height_fixer">',
		window_tpl:            '<div class="fw_win_wrapper">',
		overlay_tpl:           '<div class="fw_win_overlay_wrapper">',
		content_container_tpl: '<div class="fw_win_content_container wns">',
		content_tpl:           '<div class="fw_win_content fw_win_common_offset">',
		block_content_tpl:     '<div class="fw_win_content_block">',

		// Указатели на DOM элемента среды
		doc:  null,
		win:  null,
		html: null,
		body: null,

		// Динамические данные окружения, меняются при ресайзе
		doc_width:  null,
		doc_height: null,
		win_width:  null,
		win_height: null,

		// Динамические данные, общие для всех окон
		start_z_index: 5000,
		scroll_blocks: 0,
		win_locks:     0,

		// Вспомогательные флаги
		is_mobile_without_view_port: false,
		is_ie7:    false,
		is_iPhone: false
	};

	var touch_started = false;
	var allow_touch_scrolling = false;
	
	// Эти функции обычно содержаться в другом месте.
	// В идеале использовать основной функционал работающий в системе для определения этих параметров.
	// Соответственно переписав их вызовы далее по коду.
	var $lib = {
		isMobileDeviceWithoutViewPort: fw_MAMMON.isMobileDeviceWithoutViewPort,
		isIE7: fw_MAMMON.isIE7,
		isiPhone: fw_MAMMON.isiPhone,
		setOnBeforeUnload: fw_MAMMON.setOnBeforeUnload,
		clearOnBeforeUnload: fw_MAMMON.clearOnBeforeUnload,
		fixBodyScroll: fw_MAMMON.fixBodyScroll.setNewFix //На вход массив с полем: fix_class
	};

	// Набор приватных функций.
	function setDynParams(){
		main_options.doc_height = main_options.doc.height();
		main_options.doc_width = main_options.doc.width();
		main_options.win_height = getSmartWindowHeight();
		main_options.win_width = main_options.win.width();
	}
	function getSmartWindowHeight(){
		if(typeof(window.innerHeight) != "undefined"){
			var zoomLevel = document.documentElement.clientWidth / window.innerWidth;
			return window.innerHeight * zoomLevel;
		}
		else{
			return main_options.win.height();
		}
	}
	function setInitialParams(){
		main_options.doc = $(document);
		main_options.win = $(window);
		main_options.body = $("body:first");
		main_options.html = $("html:first");
		main_options.is_ie7 = $lib.isIE7();
		main_options.is_iPhone = $lib.isiPhone();
		main_options.is_mobile_without_view_port = $lib.isMobileDeviceWithoutViewPort();
		main_options.win.bind('resize', function() {
				updateAllOnResize();
		});
	}
	function updateAllOnResize(){
		setDynParams();
		for(var id in $fw_win.instances){
			if($fw_win.instances.hasOwnProperty(id)){
				$fw_win.instances[id].updateOnResize();
			}
		}
	}
	function createInstance(options, callbacks){
		var merged_options = $.extend({}, default_options, options);
		var merged_callbacks = $.extend({}, default_callbacks, callbacks);
		function fixInputsBlur(){
			if(main_options.is_iPhone){
				$($instance.window).on("blur.fw_win", "select,input", function(){
					fixScroll();
				});
			}
		}
		
		function closeOnClickEvents() {
			$($instance.overlay).bind("click.fw_win",function(e){
				if(e.target == this){
					$instance.close();
				}
			});
			if($instance.options.use_scroll_fix) {
				$($instance.fix_body_width_container).bind("click.fw_win", function (e) {
					if (e.target == this) {
						$instance.close();
					}
				});
			}
			$($instance.window).bind("click.fw_win",function(e){
				if(e.target == this){
					$instance.close();
				}
			});
			$($instance.close_button).on("click.fw_win",function(e){
				if(e.target == this){
					$instance.close(true);
					return;
				}
				var close_obj = this;
				$(e.target).parents().each(
					function() {
						if (close_obj == this) {
							$instance.close(true);
							return;
						}
					}
				);
			});
		}
		function disableScrollOnDocument(){
			if (!main_options.is_mobile_without_view_port){
				// Возвращаем обратно пользователя, если он нажал на кнопки прокруток.
				// Не делаем ничего, если он скроллит тачем (тач фиксит другая тема).
				main_options.win.on('scroll.fw_win_'+$instance.id, function (){
					if (!touch_started) {
						fixScroll();
					}
				});
				// Устанавливаем флаг тача
				main_options.win.on('touchstart.fw_win_'+$instance.id, function () {
					touch_started = true;
				});
				main_options.win.on('touchend.fw_win_'+$instance.id, function () {
					touch_started = false;
				});
				// Запрещаем скроллить тачем, если пытаются скролллить не наше окно
				main_options.win.on('touchmove.fw_win_'+$instance.id, function (e) {
					if (!allow_touch_scrolling) {
						fw_MAMMON.preventDefaultSafe(e);
					}
				});
			}
		}
		function fixScroll(){
			window.scrollTo($instance.win_scroll_left, $instance.win_scroll_top);
		}
		function allowScrollOnContent() {
			if (!main_options.is_mobile_without_view_port){
				// Имея наше окно разрешаем пользователю скроллить в нём.
				// Фиксим скролл на iPhone
				$($instance.window).on('touchstart.fw_win', function () {
					allow_touch_scrolling = true;
					var startTopScroll = this.scrollTop;
					if (startTopScroll <= 0)
						this.scrollTop = 1;
					if (startTopScroll + this.offsetHeight >= this.scrollHeight)
						this.scrollTop = this.scrollHeight - this.offsetHeight - 1;
					if (this.scrollTop == 0) {
						allow_touch_scrolling = false;
					}
				});
				$($instance.window).on('touchend.fw_win', function () {
					allow_touch_scrolling = false;
				});
			}
		}
		function blockScroll(){
			if(!main_options.is_mobile_without_view_port) {
				// Убираем скролл на контенте.
				main_options.scroll_blocks++;
				if (main_options.is_ie7) {
					main_options.html.css({overflow: "hidden"});
				}
				else {
					main_options.body.css({overflow: "hidden"});
				}
			}
		}
		function unBlockScroll(){
			if(!main_options.is_mobile_without_view_port) {
				if (--main_options.scroll_blocks == 0) {
					if (main_options.is_ie7) {
						main_options.html.css({overflow: ""});
					}
					else {
						main_options.body.css({overflow: ""});
					}
				}
			}
		}
		function dropAdditionalEventsAfterClose(){
			main_options.doc.off(".fw_win");
			main_options.win.off('.fw_win_'+$instance.id);
		}
		function updateOnResize(){
			var new_win_styles = {
				top: $instance.win_scroll_top,
				left: (main_options.is_mobile_without_view_port) ? 0 : $instance.win_scroll_left
			};
			if(!main_options.is_mobile_without_view_port){
				new_win_styles['height'] = main_options.win_height;
			}
			var new_overlay_styles = {
				// Временный фикс - неправильно рассчитывается ширина на iPhone\Safari
				//width:  (main_options.is_ie7) ? main_options.win_width  : main_options.doc_width,
				height: main_options.doc_height
			};
			$instance.overlay.css(new_overlay_styles);
			$instance.window.css(new_win_styles);
			
			if(!main_options.is_mobile_without_view_port && $instance.options.fixed_height){
				var new_content_styles = {
					height: main_options.win_height - $instance.options.offset - $instance.options.top_offset - $instance.title.height()
				};
				$instance.content.css(new_content_styles);
			}
			if($instance.options.onAfterResize){
				$instance.options.onAfterResize($instance);
			}
		}
		function checkLock(){
			return confirm("Вы действительно хотите закрыть окно?");
		}
		function setWinDynParams(){
			// Используются для возврата значений после закрытия окна.
			$instance.win_scroll_left = main_options.win.scrollLeft();
			$instance.win_scroll_top = main_options.win.scrollTop();
		}
		function lock(){
			if(!$instance.locked){
				$instance.locked = true;
				if(main_options.win_locks == 0){
					$lib.setOnBeforeUnload("Вы действительно хотите закрыть окно?");
				}
				main_options.win_locks++;
			}
		}
		function unlock(){
			if($instance.locked){
				$instance.locked = false;
				main_options.win_locks--;
				if(main_options.win_locks == 0){
					$lib.clearOnBeforeUnload();
				}
			}
		}
		function close(force){
			if($instance.locked){
				if(!force){
					if(!checkLock()){
						return;
					}
				}
				unlock();
			}
			if(!$instance.callbacks.onBeforeClose || $instance.callbacks.onBeforeClose($instance)){
				$instance.overlay.remove();
				$instance.window.remove();
				unBlockScroll();
				dropAdditionalEventsAfterClose();
				delete($fw_win.instances[$instance.id]);
				$instance.callbacks.onAfterClose && $instance.callbacks.onAfterClose($instance);
			}
		}

		function changeTitle(title){
			$instance.title = $(main_options.title_tpl).html(title).append($instance.close_button);
			$instance.content_container.find('.fw_win_title').replaceWith($instance.title);
			closeOnClickEvents();
		}
		
		function processBeforeOpen(){
			blockScroll();
			setDynParams();
			setWinDynParams();
			$instance.callbacks.onBeforeOpen && $instance.callbacks.onBeforeOpen($instance);
		}
		function processAfterOpen(){
			$instance.id = $instance.window.getUniqId();
			$fw_win.instances[$instance.id] = $instance;
			updateOnResize();
			disableScrollOnDocument();
			allowScrollOnContent();
			fixInputsBlur();
			closeOnClickEvents();
			$instance.callbacks.onAfterOpen && $instance.callbacks.onAfterOpen($instance);
			// Регистрируем окно у управляющего объекта
		}
		
		var $instance = {
			id: null,
			window: null,
			overlay: null,
			content_container: null,
			content: null,
			fix_body_width_container: null,
			close_button: null,
			title: null,
			content_container_block: null,
			options: merged_options,
			callbacks: merged_callbacks,
			win_scroll_left: 0,
			win_scroll_right: 0,
			locked: false,
			//Паблик функции для доступа к окну.
			lock: lock,
			unlock: unlock,
			close: close,
			updateOnResize: updateOnResize,
			changeTitle: changeTitle
		};
		// Выполняем подготовки к инициализации окна
		processBeforeOpen();

		if($instance.options.lock_page){
			$instance.lock();
		}
		// Определяем стили и заводим все элементы окна
		var current_overlay_styles = {
			zIndex: main_options.start_z_index++
		};
		var current_win_styles = {
			zIndex: main_options.start_z_index++
		};
		var current_content_styles = {
			height: $instance.options.height,
			margin: $instance.options.offset+"px",
			zIndex: main_options.start_z_index++
		};
		if($instance.options.block_mode){
			current_content_styles.display = "block";
			current_content_styles.marginTop = $instance.options.top_offset+"px";
			// Для этого мода оборачиваем наше окно в центрирующий контейнер
			var current_content_block_styles = {
				width: $instance.options.width
			};
			$instance.content_container_block = $(main_options.block_content_tpl).css(current_content_block_styles);
		}
		else{
			current_content_styles.margin = $instance.options.offset+"px";
			current_content_styles.width = $instance.options.width;
		}
		$instance.overlay = $(main_options.overlay_tpl).css(current_overlay_styles).addClass($instance.options.overlay_class);
		$instance.window = $(main_options.window_tpl).css(current_win_styles).addClass($instance.options.window_class);
		$instance.content_container = $(main_options.content_container_tpl).css(current_content_styles).addClass($instance.options.container_class);
		$instance.content = $(main_options.content_tpl).addClass($instance.options.content_class);
		if(!$instance.options.without_padding){
			$instance.content.addClass("fw_win_common_offset")
		}
		$instance.close_button = $(main_options.close_but_tpl);
		$instance.title = $(main_options.title_tpl).html($instance.options.title).append($instance.close_button);
		// Добавляем в DOM
		$instance.content_container.html($instance.title).append($instance.content.html($instance.options.content));
		var workspace = $instance.window;
		if($instance.options.use_scroll_fix && !main_options.is_mobile_without_view_port){
			$instance.fix_body_width_container = $("<div>").addClass($instance.options.fix_scroll_class);
			$instance.window.html($instance.fix_body_width_container);
			workspace = $instance.fix_body_width_container;
		}
		if(!main_options.is_mobile_without_view_port && !$instance.options.block_mode){
			workspace.html($(main_options.fixer_tpl));
		}
		if($instance.options.block_mode){
			workspace.html($instance.content_container_block.html($instance.content_container));
		}
		else{
			workspace.prepend($instance.content_container);
		}
		main_options.body.append($instance.overlay);
		if(main_options.is_mobile_without_view_port){
			$instance.overlay.append($instance.window);
		}
		else {
			main_options.body.append($instance.window);
		}
		if($instance.options.use_scroll_fix){
			$lib.fixBodyScroll({fix_class: $instance.options.fix_scroll_class});
		}
		processAfterOpen();
		return $instance;
	}
	// Инициализация начальных параметров для управляющего объекта.
	$fw_win = {
		instances: {},
		create: createInstance
	};
	$(setInitialParams);
	return $fw_win;
}();