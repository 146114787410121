(function($){
    $.fn.lbSlider = function(options) {
        var options = $.extend({
            leftBtn: '.leftBtn',
            rightBtn: '.rightBtn',
            visible: 3,
            autoPlay: false,  // true or false
            autoPlayDelay: 10,  // delay in seconds
            dots: false
        }, options);
        var make = function() {
            var activSlide = 0;
            $(this).css('overflow', 'hidden');
            
            var thisWidth = $(this).width();
            var mod = thisWidth % options.visible;
            if (mod) {
                $(this).width(thisWidth - mod); // to prevent bugs while scrolling to the end of slider
            }
            
            var el = $(this).children('ul');
            el.css({
                position: 'relative',
                left: '0'
            });
            var leftBtn = $(options.leftBtn), rightBtn = $(options.rightBtn);

            var sliderFirst = el.children('li').slice(0, options.visible);
            var tmp = '';
            sliderFirst.each(function(){
                tmp = tmp + '<li>' + $(this).html() + '</li>';
            });
            sliderFirst = tmp;
            var sliderLast = el.children('li').slice(-options.visible);
            tmp = '';
            sliderLast.each(function(){
                tmp = tmp + '<li>' + $(this).html() + '</li>';
            });
            sliderLast = tmp;

            var elRealQuant = el.children('li').length;
            el.append(sliderFirst);
            el.prepend(sliderLast);
            var elWidth = el.width()/options.visible;
            el.children('li').css({
                float: 'left',
                width: elWidth
            });
            var elQuant = el.children('li').length;
            el.width(elWidth * elQuant);
            el.css('left', '-' + elWidth * options.visible + 'px');

            function disableButtons() {
                leftBtn.addClass('inactive');
                rightBtn.addClass('inactive');
            }
            function enableButtons() {
                leftBtn.removeClass('inactive');
                rightBtn.removeClass('inactive');
            }

            leftBtn.click(function(event){
                event.preventDefault();
                if (!$(this).hasClass('inactive')) {
                    disableButtons();
                    el.animate({left: '+=' + elWidth + 'px'}, 300,
                        function(){
                            activSlide--;
                            if(activSlide < 0) activSlide = elRealQuant -1;
                            if (options.dots) activDots(activSlide);
                            
                            if ($(this).css('left') == '0px') {
								$(this).css('left', '-' + elWidth * elRealQuant + 'px');
							}
                            enableButtons();
                        }
                    );
                }
                return false;
            });

            rightBtn.click(function(event){
                event.preventDefault();
                if (!$(this).hasClass('inactive')) {
                    disableButtons();
                    el.animate({left: '-=' + elWidth+ 'px'}, 300,
                        function(){
                            activSlide++;
                            if(activSlide >= elRealQuant) activSlide = 0;
                            if (options.dots) activDots(activSlide);
                            
                            if ($(this).css('left') == '-' + (elWidth * (options.visible + elRealQuant)) + 'px') {
								$(this).css('left', '-' + elWidth * options.visible + 'px');
							}
                            enableButtons();
                        }
                    );
                }
                return false;
            });

            if (options.autoPlay) {
                function aPlay() {
                    rightBtn.click();
                    delId = setTimeout(aPlay, options.autoPlayDelay * 1000);
                }
                var delId = setTimeout(aPlay, options.autoPlayDelay * 1000);
                el.hover(
                    function() {
                        clearTimeout(delId);
                    },
                    function() {
                        delId = setTimeout(aPlay, options.autoPlayDelay * 1000);
                    }
                );
            }
            
            var elDots = $(this).children('.dots');
            if (options.dots && elDots.size()) {
                
                function activDots(indexSel) {
                    elDots.find('span').removeClass('sel');
                    elDots.find('span').eq(indexSel).addClass('sel');
                }
                
                for(var i = 0; i < elRealQuant; i++){
                    elDots.append('<span data-index="' + i + '"></span>');
                }
                activDots(0);
                
                elDots.find('span').click(function(event){
                    event.preventDefault();
                    activSlide = $(this).data('index');
                    activDots(activSlide);
                    if (!leftBtn.hasClass('inactive')) {
                        disableButtons();
                        el.animate({left: '-' + elWidth * parseInt(activSlide + 1) + 'px'}, 300,
                            function(){
                                enableButtons();
                            }
                        );
                    }
                    
                    return false;
                });
            }
        };
        return this.each(make);
    };
})(jQuery);