var fw_Win_active;
$(function () {

	var SetForms = new Forms();

	$(document)
	// Тест драйв
		.on('click', '.test-drive', function () {
			SetForms.getTestDriveForm(false, false, $(this).data('model'));
			return false;
		})
		// Кредит
		.on('click', '.calc-credit', function () {
			SetForms.getCreditCalcForm('', false, $(this).data('price'), $(this).data('model_name'), $(this).data('model_image'));
			return false;
		})
		// Обратная связь
		.on('click', '.send-feedback', function () {
			SetForms.getFeedBackForm();
			return false;
		})
		// Получить предложение
		.on('click', '.special-offer', function () {
			SetForms.getIndividualForm('', false, $(this).data('model'), $(this).data('equipment'));
			return false;
		})
		.on('click', '.new-faq-form', function () {
			SetForms.getFAQForm();
			return false;
		})
		.on('click', '.new-used-form', function () {
			SetForms.getUsedForm();
			return false;
		});


	if ($('#form-root').length)
		SetForms.getFeedBackForm(false, $('#form-root'));

	if ($('#form-model').length)
		SetForms.getIndividualForm(false, $('#form-model'));

});


var price; // monkey code to credit

var Forms = (function () {
	// var isMobile = fw_MAMMON.isMobileDevice();
	var SELECTORS = {
		datepicker: '*[name=date]',
		timepicker: '*[name=time]'
	};

	function Forms() {
		$('body').addClass('body_scroll_fix');
		fw_MAMMON.fixBodyScroll.setNewFix();
		initFormWrap();

		$(document).on('click', '.i_agree_personal_data', function () {
			createWin(
				$('.i_agree_personal_data_title').html(),
				$('.i_agree_personal_data_text').html()
			);
			return false;
		});
		$(document).on('click', '.more_about_security', function () {
			createWin(
				$('.more_about_security_title').html(),
				$('.more_about_security_text').html()
			);
			return false;
		});
	}


	Forms.prototype.getTestDriveForm = function (title, wrapper, modelCode) {
		title = title || 'Запись тест драйв';
		createPopUpForm(wrapper, false, title, '/ajax/forms/getTestDriveForm/', {}, function (resultWrapper) {
			var select = $(resultWrapper).find('*[name=model]');
			select.val(
				select.find('*[data-model-code="' + modelCode + '"]').attr('value')
			);
		});
	};
	Forms.prototype.getCreditCalcForm = function (title, wrapper, setPrice, modelName, modelImage) {
		title = title || 'Рассчитать кредит';
		price = setPrice; // monkey code to credit
		createPopUpForm(wrapper, true, title, '/ajax/forms/getCreditCalcForm/', {}, function (resultWrapper) {
			_CREDIC_CALC.initCreditCalc({calcButtonSelectText: '#calc_credit_button .text'});
			resultWrapper.find('.car_info_image').attr('src', modelImage);
			resultWrapper.find('.car_info_credit_price .numeral').html(fw_MAMMON.formattedNumber(setPrice));
			resultWrapper.find('.car_name').html(modelName);
			resultWrapper.find('*[name=price]').val(setPrice);
			resultWrapper.find('*[name=model]').val(modelName);
		});
	};
	Forms.prototype.getFeedBackForm = function (title, wrapper) {
		title = title || 'Обратная связь';
		createPopUpForm(wrapper, false, title, '/ajax/forms/getFeedBackForm/');
	};

	Forms.prototype.getFAQForm = function (title, wrapper) {
		title = title || 'Задать вопрос';
		createPopUpForm(wrapper, false, title, '/ajax/faq/getFaqForm/');
	};

	Forms.prototype.getUsedForm = function (title, wrapper) {
		title = title || 'Запрос подробной информации';
		createPopUpForm(wrapper, false, title, '/ajax/forms/getCarUsedInfoForm/', {}, function(resultWrapper) {
			resultWrapper.find('input[name=model_name]').val(model_name);
			resultWrapper.find('input[name=car]').val(model_short_name);
			resultWrapper.find('input[name=price_clear]').val(price_clear);
			resultWrapper.find('input[name=TextYear]').val(year);
			resultWrapper.find('input[name=TextRun]').val(run);
			resultWrapper.find('input[name=territory_oid]').val(territory_oid);
			resultWrapper.find('.form-car-info-used .car-name').text(model_short_name + ', ' + year + 'г., пробег ' + run + ' км');
			resultWrapper.find('.form-car-info-used .car-price').html(text_price);
			resultWrapper.find('.form-car-info-used .car-image').attr('src', model_img_src);
		});
	};

	Forms.prototype.getIndividualForm = function (title, wrapper, modelName, equipmentName) {
		title = title || 'Получить предложение';
		createPopUpForm(wrapper, false, title, '/ajax/forms/getIndividualForm/', {}, function (resultWrapper) {
			var modelSelect = $(resultWrapper).find('*[name=model]'),
				equipmentSelect = $(resultWrapper).find('*[name=equipment]'),
				modelId = modelSelect.find('option[value="' + modelName + '"]').data('model_id');

			modelSelect.val(modelName);

			getEquipmentSelectContent(modelId, equipmentSelect, equipmentName);
			modelSelect.change(function () {
				modelId = modelSelect.find('option[value="' + modelSelect.val() + '"]').data('model_id');
				getEquipmentSelectContent(modelId, equipmentSelect);
			});
		});


	};


	function getEquipmentSelectContent(modelId, el, equipmentName) {
		fw_MOJAX.send({
			url: '/ajax/forms/getEquipmentSelectContent/',
			data: {
				model_id: modelId
			},
			to: el,
			overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
			overlay_to: el,
			user_success: function (data) {
				if (equipmentName)
					$(el).val(equipmentName);
				initFormWrap($(el).parent());
			}
		});
	}

	function createPopUpForm(wrap, isLockWin, title, url, post_data, done_function) {
		post_data = post_data || {};

		if (!wrap) {
			fw_Win_active = createWin(title, '', isLockWin);
			wrap = $(fw_Win_active.content);
		}

		fw_MOJAX.send({
			url: url,
			data: post_data,
			to: wrap,
			overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
			overlay_to: wrap,
			user_success: function (data) {
				// if(data && data.major_form_replace_values)
				// 	majorFormReplaceValues($(wrap).find('.major_form'), data.major_form_replace_values);
				if (done_function)
					done_function(wrap);
				initFormWrap(wrap);
			}
		});
	}

	function createWin(title, content, isLockWin) {
		return fw_WIN.create({
			offset: 0,
			title: title ? '<h1>' + title + '</h1>' : '',
			container_class: "form_window",
			content_class: "fw_win_form_content",
			overlay_class: "form_overlay",
			content: content ? content : '',
			lock_page: isLockWin ? isLockWin : false
		}, {
			onAfterOpen: function (inst) {
				// inst.close_button.append('<span class="hidden-xs">Закрыть</span><div class="block_icon icon_plus black rotate_45"></div>');
				$('.page').addClass('-blur');
			},

			onAfterClose: function (inst) {
				$('.page').removeClass('-blur');
			}
		});
	}

	function initFormWrap(parent) {
		parent = parent || document;
		parent = $(parent);

		// if(!isMobile)
		// 	parent.find('.default_ik_select, .popup-select').ikSelect();

		initTestDrivePicker();

		$('.field_wrapper select').ikSelect({
			autoWidth: false,
			ddFullWidth: false,
			ddMaxHeight: 295
		});

		parent.find('.icon-input.-date').click(function () {
			$(this).siblings('input').focus();
		});
		parent.find('.icon-input.-time').click(function () {
			$(this).siblings('input').click().focus();
			return false;
		});

		parent
			.on('change', '.input-layout input', function () {
				checkInputLayout($(this));
			});
		// .on('click', '.close_popup_form', function () {
		// 	$(this).parents('.fw_win_content_container').find('.fw_win_close_button').click();
		// 	return false;
		// });


	}

	function destroyFormWrap(parent) {
		// parent.find(SELECTORS.datepicker).datepicker("destroy");
		// parent.find(SELECTORS.timepicker).timepicker('remove');
	}

	function checkInputLayout(el) {

	}

	function initTestDrivePicker() {
		var inst = $('input[name=time]');
		var timePicker = inst.timepicker({
			defaultTime: "13:00",
			useBackground: false,
			countColumn: 1,
			orientation: 't',
			beforeShow: function () {
				$('.ftype_time input').parent().append($('.container_timepicker'));
			}
		});
		var icon = _FORMS.getInputIcon(inst);
		var date = new Date();
		date.setDate(date.getDate() + 1);
		if (icon.length) {
			icon.click(function (e) {
				!timePicker.isVisible() ? timePicker.show() : timePicker.hide();
				fw_MAMMON.preventDefaultSafe(e);
			});
		}
		_FORMS.initDatePicker(date, timePicker);
	}

	function initDatePicker(default_date, timePicker) {
		var inst = $('input[name=date]');
		var icon = _FORMS.getInputIcon(inst);
		inst.datepicker({
			inline: true,
			minDate: new Date(),
			beforeShow: function (input, obj) {
				$(input).after($(input).datepicker('widget'));
				$(input).addClass('active');
			},
			onClose: function (input, obj) {
				$(obj.input).removeClass('active');
			},
			onSelect: function (dateText, inst) {
				$(this).trigger('change');
				if (!timePicker) return true;
				var selected_date = Date.parse(dateText.split('.').reverse().join('.')),
					date_obj = new Date(),
					current_date = Date.parse((date_obj.getMonth() + 1) + '/' + date_obj.getDate() + '/' + date_obj.getFullYear());
				if (selected_date === current_date) {
					var minutes = date_obj.getMinutes(),
						time = (date_obj.getHours() + 1) + ':' + (minutes < 10 ? '0' : '') + minutes;
					timePicker.disabledUntilTime(time);
				}
				else {
					timePicker.clearDisabled();
				}
				return true;
			}
		});
		if (icon.length) {
			icon.click(function (e) {
				if (inst.datepicker('widget').is(':hidden')) {
					inst.datepicker('show');
				}
				fw_MAMMON.preventDefaultSafe(e);
			});
		}

		if (default_date != 'undefined') {
			inst.datepicker('setDate', default_date);
			inst.datepicker("option", "dateFormat", "dd.mm.yy");
		}

		if (fw_MAMMON.isMobileDevice()) {
			$('.ftype_date input,.ftype_time input').focus(function () {
				$(this).blur();
			});
		}
	}

	return Forms;
})();